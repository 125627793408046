import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Paper,
  TextField,
  CircularProgress,
  Popover,
  IconButton,
  Tooltip as MuiToolTip,
  Typography,
  Portal,
  Snackbar,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import PauseRoundedIcon from "@material-ui/icons/PauseRounded";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import EcoIcon from "@material-ui/icons/Eco";
import ReactMarkdown from "react-markdown";
import Tooltip from "recharts/lib/component/Tooltip";

import "./style.css";
import {
  ArchiveIcon,
  CheckIcon,
  ClapIcon,
  ClockCountIcon,
  ClockIcon,
  CodeIcon,
  CodeViewIcon,
  CommentIcon,
  CommentsIcon,
  ConfusionMatrixIcon,
  DownloadIcon,
  DownloadModelIcon,
  EcoTrainingIcon,
  EditIcon,
  EditObjectiveIcon,
  FullScreenIcon,
  MenuIcon,
  NotificationCheckIcon,
  ShareTreeIcon,
  TrainingPausedIcon,
  TrainingPlanIcon,
  TrainingQueuedIcon,
  TrainingRunningIcon,
  TrainingStoppedIcon,
} from "Components/Icon";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LineChart,
  ReferenceDot,
} from "recharts";
import Comments from "./Comments";
import CodeView from "./CodeView";
import axios from "axios";
import { API_URL, GlobalContext } from "Context/GlobalState";
import {
  convertSecondsToDay,
  formatDate,
  formatNumberToKMBT,
  nFormatter,
  numberWithCommas,
  numberWithSpaces,
} from "utils/format";
import aucImg from "assets/icons/auc@2x.png";
import rocImg from "assets/icons/roc@2x.png";
import matrixImg from "assets/icons/matrix@2x.png";
import trainingPlanImg from "assets/icons/training_plan@2x.png";
import commentsImg from "assets/icons/comments@2x.png";
import codeviewImg from "assets/icons/codeview@2x.png";
import downloadImg from "assets/icons/download@2x.png";
import menuImg from "assets/icons/menu@2x.png";
import treeImg from "assets/icons/tree@2x.png";
import UserPopup from "Components/UserPopup";
import useOnScreen from "utils/useOneScreen";
import TrainingPlan from "./TrainingPlan";
import ConfusionMatrix from "./ConfusionMatrix";
import { trainingState } from "utils";
import { withStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import ClapButton from "Components/ClapButton";
import EmptyGraph from "./EmptyGraph";
import Sustainability, { HtmlTooltip } from "Components/Sustainability";
import Inference from "./Inference";
import CopyToClipboard from "react-copy-to-clipboard";

const RenderToolTip = (props) => {
  const { active, index, payload, label, category } = props;
  let ids = payload && payload.map((o) => o.dataKey);
  let filtered =
    payload &&
    payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));

  console.log("CYCLE-category", filtered[0]?.payload, category)
  return (
    active && (
      <Paper className="defect-bar-tooltip" variant="outlined">
        <div className="bold-700-12">
          At epoch: {filtered[0]?.payload.epochSeq}, cycle: {filtered[0]?.payload.cycleNo}
        </div>
        {filtered &&
          filtered.map((entry, index) => {
            let name = entry.name;
            if (category === "keypoint_detection") {
              name = name.split("Accuracy").join("PCK");

            }
            return (
              <span className={entry.name} key={`item-${entry.name}`}>
                {name}: {(+entry.value).toFixed(2)}
              </span>
            );
          })}
      </Paper>
    )
  );
};

const options = { threshold: 0.6 };

const renderEpochTick = (tickProps) => {
  const { x, y, payload, visibleTicksCount } = tickProps;
  const { value, index } = payload;

  let margin = 5;
  if (visibleTicksCount <= 80) {
    margin = 5;
  } else if (visibleTicksCount <= 100) {
    margin = 10;
  } else if (visibleTicksCount <= 180) {
    margin = 20;
  }
  const epochNo = index + 1;

  if (index === 0) {
    return (
      <text
        style={{ fontSize: 10, textAnchor: "middle", fill: "rgb(102,102,102)" }}
        x={x}
        y={y + 10}
        textAnchor="middle"
      >{`${index}`}</text>
    );
  } else if (index % margin === 0 && visibleTicksCount - 4 > index) {
    return (
      <text
        style={{
          margin: "1px",
          fontSize: 10,
          textAnchor: "middle",
          fill: "rgb(102,102,102)",
        }}
        x={x}
        y={y + 10}
        textAnchor="middle"
      >{`${index}`}</text>
    );
  } else if (epochNo === visibleTicksCount) {
    return (
      <text
        style={{
          margin: "1px",
          fontSize: 10,
          textAnchor: "middle",
          fill: "rgb(102,102,102)",
        }}
        x={x}
        y={y + 10}
        textAnchor="middle"
      >{`${index}`}</text>
    );
  }
  return null;
};

const renderOptimumCycleContent = ({ viewBox }) => {
  // console.log("viewBox", viewBox)
  const { x, y, width, height } = viewBox;
  const d = 6;
  const r = d / 2;
  const transform = `translate(${x - r} ${y - d - 5})`;
  return (
    <g
      transform={transform}
      style={{ cursor: "pointer" }}
      //   onClick={e => console.log("hello")}
    >
      <circle
        cx={d / 2}
        cy={d + 2}
        r={r}
        stroke="#5DD39E"
        stroke-width="1"
        fill="#5DD39E"
      />
      <circle
        cx={d / 2}
        cy={d + height + 8}
        r={r}
        stroke="#5DD39E"
        stroke-width="1"
        fill="#5DD39E"
      />
    </g>
  );
};

const UPDATE_EXPERIMENT_INTERVAL = 12000;

const Row = (props) => {
  const { user, setUserUpdate } = useContext(GlobalContext);
  const { experimentData, expIndex } = props;
  const [experiment, setExperiment] = useState({
    ...experimentData,
    aggregate_epochs: [
      ...(experimentData.aggregate_epochs.length > 0
        ? [
            {
              created_date: "2021-12-15T09:05:56.368786Z",
              cycleNo: 1,
              epochNo: 0,
              epochSeq: 0,
              status: 3,
              trainingAccuracy: null,
              trainingLoss: null,
              updated_date: "2021-12-15T09:44:39.907192Z",
              validationAccuracy: null,
              validationLoss: null,
            },
          ]
        : []),
      ...experimentData.aggregate_epochs,
    ],
  });
  const [fullscreenAccuracy, setFullscreenAccuracy] = useState(false);
  const [fullscreenLoss, setFullscreenLoss] = useState(false);
  const [objectiveDisabled, setObjectiveDisabled] = useState(true);
  const [showComments, setShowComments] = useState(false);
  const [showConfusionMatrix, setShowConfusionMatrix] = useState(false);
  const [showCodeView, setShowCodeView] = useState(false);
  const [showTrainingPlan, setShowTrainingPlan] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [selectedEpoch, setSelectedEpoch] = useState({});
  const [downloading, setDownloading] = useState(false);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [echoTraining, setEchoTraining] = useState(false);
  const [error, setError] = useState(null);
  const [claps, setClaps] = useState(0);
  const [flashCycle, setFlashCycle] = useState(false);
  const objectiveRef = useRef(null);
  const [objective, setObjective] = useState("");
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const experimentRef = useRef();
  const isVisible = useOnScreen(experimentRef, options);
  const [outOfFlops, setOutOfFlops] = useState(false);
  const [optimumCycle, setOptimumCycle] = useState(null);
  const [downloads, setDownloads] = useState(0);
  const [copied, setCopied] = useState(false);
  let cancelExpToken, cancelTimeToken;
  let subdataset = null;
  try {
    subdataset = experiment.dataset_parameters.subdataset.replace(/\'/g, '"');
    subdataset = JSON.parse(subdataset)
    console.log("subdataset", subdataset, Object.keys(subdataset).length)
    if (Object.keys(subdataset).length === 0) {
      subdataset = null
    }
  } catch (error) {
    subdataset = null
    console.log("subdataset-error", error, experiment.dataset_parameters.subdataset, subdataset)
  }

  const showOutOfFlopsMessage = (e) => {
    setOutOfFlops(true);
    setTimeout(() => {
      setOutOfFlops(false);
    }, 3000);
  };

  // console.log("SINGLE-EXPERIEMNT", experiment, experiment.id,  user);
  const handleFullscreenAccuracy = useCallback(() => {
    setFullscreenAccuracy(!fullscreenAccuracy);
  });

  const handleFullscreenLoss = () => {
    setFullscreenLoss(!fullscreenLoss);
  };

  const onObjectiveClick = (event) => {
    setObjectiveDisabled(!(experiment?.userId?.id === user.user_id));
    // objectiveRef.current.focus();
  };

  const onObjectiveBlur = (event) => {
    setObjective(event.target.value);

    const token = localStorage.getItem("_key_usr_tkn");

    const config = {
      method: "PATCH",
      url: `${API_URL}/experiments/${experiment.id}/`,
      data: { objective: event.target.value },
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
      });
  };

  const handleClickAway = (event) => {
    setObjectiveDisabled(true);
  };

  useEffect(() => {
    let intervalDetails, intervalTime;
    // && [trainingState.inQueue, trainingState.training].includes(experiment.status)
    if (isVisible) {
      intervalDetails = setInterval(() => {
        getExperimentUpdates();
        // The logic of changing counter value to come soon.
      }, UPDATE_EXPERIMENT_INTERVAL);
    } else {
      clearInterval(intervalDetails);
    }
    return () => {
      clearInterval(intervalDetails);
    };
  }, [isVisible, experiment]);

  // console.log("AAAA-LATEST", experiment.id, selectedEpoch, experiment)

  const getExperimentUpdates = () => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    //Check if there are any previous pending requests
    if (typeof cancelExpToken != typeof undefined) {
      cancelExpToken.cancel("Operation canceled due to new request.");
    }
    const epoch_updated_date =
      experiment.aggregate_epochs.length > 1
        ? experiment.aggregate_epochs[experiment.aggregate_epochs.length - 1]
            .updated_date
        : "";
    const aggregate_cycle_updated_date =
      experiment.aggregatecycles.length > 0
        ? experiment.aggregatecycles[experiment.aggregatecycles.length - 1]
            .updated_date
        : "";
    const last_epoch =
      experiment.aggregate_epochs[experiment.aggregate_epochs.length - 1]
        ?.epochSeq || 0;
    cancelExpToken = axios.CancelToken.source();
    const config = {
      method: "get",
      url: `${API_URL}/experiment-details-ping/${experiment.datasetId_id}/${experiment.id}/?epoch_updated_date=${epoch_updated_date}&aggregate_cycle_updated_date=${aggregate_cycle_updated_date}&epoch=${last_epoch}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      cancelToken: cancelExpToken.token, //Pass the cancel token to the current request
    };

    axios(config)
      .then((res) => {
        let data = res.data[0];
        const updated_aggregate_epochs = data.updated_aggregate_epochs;

        // add zero epoch only if there are real epochs returned from the server.
        let aggregate_epochs = experiment.aggregate_epochs;

        // const removeEpochs = data.removed || [];
        // let aggregate_epochs = experiment.aggregate_epochs.filter(function(element, index, arr){
        //     return !removeEpochs.includes(element.epochSeq);
        // });

        data.updated_aggregate_epochs.forEach((element, index) => {
          const updateIndex = aggregate_epochs.findIndex((item) => {
            return element.epochSeq === item.epochSeq;
          });

          if (updateIndex > 0) {
            aggregate_epochs[updateIndex] = { ...element };
          } else {
            aggregate_epochs.push(element);
          }
          // updated_aggregate_epochs.splice(index, 1)
        });

        const experimentDataLatest = {
          ...experiment,
          ...data,
          aggregate_epochs: [
            ...(aggregate_epochs.length > 0 && aggregate_epochs[0].epochNo !== 0
              ? [
                  {
                    created_date: "2021-12-15T09:05:56.368786Z",
                    cycleNo: 1,
                    epochNo: 0,
                    epochSeq: 0,
                    status: 3,
                    trainingAccuracy: null,
                    trainingLoss: null,
                    updated_date: "2021-12-15T09:44:39.907192Z",
                    validationAccuracy: null,
                    validationLoss: null,
                  },
                ]
              : []),
            ...aggregate_epochs,
          ],
          aggregatecycles: [
            ...experiment.aggregatecycles,
            ...data.updated_aggregate_cycles.filter((aggregateCycle) => {
              const object = experiment.aggregatecycles.find(
                (item) => aggregateCycle.cycleNo === item.cycleNo
              );
              return typeof object === "undefined" ? true : false;
            }),
          ],
        };

        if (experimentDataLatest.aggregate_epochs.length > 0) {
          const experimentDataLatest_with_epoch_No = {
            ...experimentDataLatest,
            aggregate_epochs: [
              ...experimentDataLatest.aggregate_epochs.sort((a, b) => {
                if (a.epochSeq < b.epochSeq) {
                  return -1;
                }
                if (a.epochSeq > b.epochSeq) {
                  return 1;
                }
                return 0;
              }),
            ],
          };

          setExperiment({
            ...experimentDataLatest_with_epoch_No,
          });
          setSelectedEpoch({
            ...experimentDataLatest.aggregate_epochs[
              experimentDataLatest.aggregate_epochs.length - 1
            ],
            epochIndex:
              experimentDataLatest.aggregate_epochs[
                experimentDataLatest.aggregate_epochs.length - 1
              ].epochSeq,
          });

          if (data.updated_aggregate_cycles.length > 0) {
            const epochNo =
              experimentDataLatest.aggregate_epochs[
                experimentDataLatest.aggregate_epochs.length - 1
              ].epochSeq;
            const userSelectedCycle = isCycleSelectable(epochNo);
            if (userSelectedCycle) {
              const payload = {
                ...userSelectedCycle,
                scores: userSelectedCycle.scores,
              };

              setSelectedCycle({ ...payload });
            } else {
              setSelectedCycle(null);
            }
          }
        } else {
          setExperiment({
            ...experimentDataLatest,
          });
        }

        setTimeLeft(data.time_left);
        const flops_updated = {
          total_available_flops: data.total_available_flops,
          user_available_flops: data.user_available_flops,
          company_available_flops:
            data.total_available_flops - data.user_available_flops,
        };
        setUserUpdate({ ...flops_updated });

        experimentDataLatest.aggregatecycles.forEach(
          (aggregateCycle, index) => {
            const epochNo = aggregateCycle.last_epoch;
            // console.log("CYCLE-PRINT-OPTIMUM", experiment.optimum_cycle, aggregateCycle.cycleNo)
            if (experimentDataLatest.optimum_cycle === aggregateCycle.cycleNo) {
              setOptimumCycle({ ...aggregateCycle });
            }
          }
        );

        setClaps(data.claps);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    setObjective(experiment.objective);
    setClaps(experiment.claps);
    setDownloads(experiment.download_counter);
    setEchoTraining(experiment?.run_sustainable_training);
    if (experiment.time_left > 0) {
      setTimeLeft(experiment.time_left);
    }
    if (experiment.aggregate_epochs.length > 1) {
      setSelectedEpoch({
        ...experiment.aggregate_epochs[experiment.aggregate_epochs.length - 1],
        epochIndex:
          experiment.aggregate_epochs[experiment.aggregate_epochs.length - 1]
            .epochSeq,
      });
    }
    if (experiment.aggregatecycles.length > 0) {
      const aggregateCycle =
        experiment.aggregatecycles[experiment.aggregatecycles.length - 1];
      const payload = { ...aggregateCycle, scores: aggregateCycle.scores };
      setSelectedCycle(payload);
      experiment.aggregatecycles.forEach((aggregateCycle, index) => {
        const epochNo = aggregateCycle.last_epoch;
        // console.log("CYCLE-PRINT-OPTIMUM", experiment.optimum_cycle, aggregateCycle.cycleNo)
        if (experiment.optimum_cycle === aggregateCycle.cycleNo) {
          setOptimumCycle({ ...aggregateCycle });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (timeLeft <= 0 || experiment.status !== trainingState.training) {
      return;
    }
    // count down timer for Time est.
    const timer = () => setTimeLeft(timeLeft - 1);

    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [timeLeft]);




  
  const downloadWeightsHandle_2 = async () => {
    setDownloading(true);
    setDownloadStarted(true); // used for displaying the download alert
    const token = localStorage.getItem("_key_usr_tkn");
    
    try {
      const response = await axios({
        url: `${API_URL}/downloadmodel/${experiment.id}/${selectedCycle.cycleNo}/`,
        method: 'GET',
        responseType: 'blob', // Important
        headers: {
          Authorization: `Token ${token}`,
        }
      });
      setDownloads(downloads + 1);
      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Set the download attribute with a filename
      link.setAttribute('download', `${experiment.id}_${selectedCycle.cycleNo}.zip`); // Replace with your desired file name and extension

      // Append to the DOM and trigger click event to start download
      document.body.appendChild(link);
      link.click();

      // Cleanup and remove the link
      link.parentNode.removeChild(link);
      setDownloading(false);
      setError(null);
    } catch (error) {
      console.error('Error downloading the file', error);
      if (error.response) {
        setError("Please make sure your credentials are correct.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
      setDownloading(false);
    }
  };





  const downloadWeightsHandle = (e) => {
    // console.log("downloadWeightsHandle", selectedCycle);
    setDownloading(true);
    setDownloadStarted(true); // used for displaying the download alert
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/getdownloadtoken/${experiment.id}/${selectedCycle.cycleNo}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        //const blob = res.data
        console.log(res.data);
        setDownloads(downloads + 1);
        let a = document.createElement("a");
        a.href = `${API_URL}/downloadmodelfile/${res.data}/`;
        a.target = "_blank";
        a.click();
        //console.log("DATA", data);
        setDownloading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setDownloading(false);
      });
  };

  const downloadWeightsHandleDisabled = (e) => {
    setFlashCycle(true);
    setTimeout(() => setFlashCycle(false), 500);
  };

  const chartClickHandler = (e) => {
    if (e === null || e.activeTooltipIndex === 0) return;
    if (experiment.aggregate_epochs.length > 0) {
      setSelectedEpoch({
        ...experiment.aggregate_epochs[e.activeTooltipIndex],
        epochIndex: e.activeTooltipIndex + 1,
      });
    }
    if (
      experiment.aggregatecycles.length > 0 &&
      experiment.aggregate_epochs.length > 0
    ) {
      const epochNo = e.activeTooltipIndex;

      const findSelectedCycle = isCycleSelectable(epochNo);
      if (findSelectedCycle) {
        const payload = {
          ...findSelectedCycle,
          scores: findSelectedCycle.scores,
        };
        console.log("CONFUSION-payload", payload);
        setSelectedCycle(payload);
      } else {
        setSelectedCycle(null);
      }
    }
  };

  const isCycleSelectable = (epochIndex) => {
    const { epochs, cycles } = experiment;
    const epochNo = epochIndex;
    // const epochPerCycle = epochs;
    // return (epochNo % epochPerCycle === 0)

    const findSelectedCycle = experiment.aggregatecycles.find(
      (aggregateCycle) => {
        const selectEpochNo = aggregateCycle.last_epoch;
        return epochNo === selectEpochNo;
      }
    );
    if (findSelectedCycle) {
      const payload = {
        ...findSelectedCycle,
        scores: findSelectedCycle.scores,
      };
      return payload;
    } else {
      return null;
    }
  };

  /// Popup open and close
  const openUserPopup = Boolean(userAnchorEl);
  const userPopupId = openUserPopup ? "simple-user-popover" : undefined;

  const handleUserPopup = (e) => {
    e.stopPropagation();
    setUserAnchorEl(e.currentTarget);
  };

  const handleCloseUserPopup = (e) => {
    setUserAnchorEl(null);
  };

  const sendClaps = () => {
    //setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "PATCH",
      url: `${API_URL}/experiments/${experiment.id}/`,
      data: { claps: 1 },
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        console.log("YOU CLAPPED", data);
        //setExperiment([...data[0]])
        //setLoading(false)
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure you are logged in.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        //setLoading(false)
      });
  };

  const handleClap = (e) => {
    sendClaps();
    setClaps(claps + 1);
  };

  const handleArchiveExperiment = (e) => {
    //e.stopPropagation(); // stop the row from being clicked

    props.archiveExperiment(experiment, expIndex);
  };

  const handleExperimentStatus = (status) => {
    console.log("experiment-status", status);
    setChangingStatus(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "POST",
      url: `${API_URL}/psr/${experiment.id}/${status}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        console.log("EXPERIMENT-STATUS", data.status, user);
        setExperiment({ ...experiment, status: data.status });

        const flops_updated = {
          total_available_flops: data.total_available_flops,
          user_available_flops: data.user_available_flops,
          company_available_flops:
            data.total_available_flops - data.user_available_flops,
        };

        if (data.status=== trainingState.paused && data.message ) {
          showOutOfFlopsMessage();
        }
        setUserUpdate({ ...flops_updated });

        //setExperiment([...data[0]])
        setChangingStatus(false);
        setError(null);
      })
      .catch((error) => {
        console.log("EXPERIMENT-STATUS-CATCH", error.response);
        if (error.response) {
          setError("Please make sure you are logged in.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setChangingStatus(false);
      });
  };

  const handleEcoTraining = (status) => {
    console.log("experiment-status", status);
    setChangingStatus(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "PATCH",
      url: `${API_URL}/experiments/${experiment.id}/`,
      data: { run_sustainable_training: +!experiment.run_sustainable_training },
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        console.log("EXPERIMENT-ECHO", data);
        setExperiment({
          ...experiment,
          run_sustainable_training: data.run_sustainable_training,
        });
        //setExperiment([...data[0]])
        setChangingStatus(false);
        setError(null);
      })
      .catch((error) => {
        console.log("EXPERIMENT-STATUS-CATCH", error.response);
        if (error.response) {
          setError("Please make sure you are logged in.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setChangingStatus(false);
      });
  };

  /// add vertical lines for OptimumCycleLine
  const OptimumCycleLine =
    experiment.aggregatecycles &&
    experiment.aggregatecycles.map((aggregateCycle, index) => {
      //  const cycleNo = aggregateCycle.cycleNo;
      //  const epochNo = (cycleNo * experiment.epochs) - (experiment.epochs - aggregateCycle.last_epoch);
      const epochNo = aggregateCycle.last_epoch;
      // console.log("CYCLE-PRINT-OPTIMUM", experiment.optimum_cycle, aggregateCycle.cycleNo)
      if (experiment.optimum_cycle === aggregateCycle.cycleNo) {
        // setOptimumCycle({ ...aggregateCycle });
        return (
          <ReferenceLine
            x={epochNo}
            width={4}
            label={<Label position="top" content={renderOptimumCycleContent} />}
            // strokeDasharray={ flashCycle ? "1 1" : "1 1"}
            strokeOpacity={flashCycle ? 0.6 : 1}
            stroke={flashCycle ? "#5DD39E" : "#5DD39E"}
            style={{
              filter: flashCycle
                ? "drop-shadow(rgba(53, 118, 167, 1) 0px 0px 2px)"
                : "",
            }}
          />
        );
      }
      return null;
    });

  /// add dots for CycleDotsTraining
  const referenceDotsAccuracy = [];
  const referenceDotsLoss = [];
  experiment.aggregate_epochs &&
    experiment.aggregate_epochs.forEach((aggregateEpoch, index) => {
      //    const aggregateCycle = experiment.aggregatecycles.find(aggregateCycle => aggregateCycle.last_epoch === aggregateEpoch.epochSeq)
      if (aggregateEpoch.cycle_done) {
        //TrainingAccuracy
        referenceDotsAccuracy.push(
          <ReferenceDot
            x={aggregateEpoch.epochSeq}
            y={(+aggregateEpoch.trainingAccuracy)}
            r={3}
            fill="#fff"
            stroke="#FFA72B"
            strokeWidth={1}
          />
        );
        // ValidationAccuracy
        referenceDotsAccuracy.push(
          <ReferenceDot
            x={aggregateEpoch.epochSeq}
            y={(+aggregateEpoch.validationAccuracy)}
            r={3}
            fill="#fff"
            stroke="#266B9A"
            strokeWidth={1}
          />
        );

        //TrainingLoss
        referenceDotsLoss.push(
          <ReferenceDot
            x={aggregateEpoch.epochSeq}
            y={(+aggregateEpoch.trainingLoss)}
            r={3}
            fill="#fff"
            stroke="#FFA72B"
            strokeWidth={1}
          />
        );
        // ValidationLoss
        referenceDotsLoss.push(
          <ReferenceDot
            x={aggregateEpoch.epochSeq}
            y={(+aggregateEpoch.validationLoss)}
            r={3}
            fill="#fff"
            stroke="#266B9A"
            strokeWidth={1}
          />
        );
      }
    });

  // console.log("selectedCycle-selectedEpoch",experiment.id, timeLeft);

  const statusTooltip = () => {
    let title = "";
    if (experiment.status == trainingState.completed) {
      title = "Experiment Completed";
    } else if (experiment.status == trainingState.training) {
      title = "Experiment in Progress​";
    } else if (experiment.status == trainingState.inQueue) {
      title = "Experiment in Queue";
    } else if (experiment.status == trainingState.paused) {
      title = "Experimet Paused​";
    } else if (experiment.status == trainingState.stopped) {
      title = "Experiment Stopped​";
    } else if (experiment.status == trainingState.aborted) {
      title = "Experiment aborted";
    }
    return title;
  };

  const showCopyMessage = (e) => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // console.log("CONFUSION-payload-out", experiment?.experimentKey, selectedCycle);
  

  const renderStatus = () => {
  
    if (experiment.status === trainingState.completed) {
      return (<CheckIcon className="icon" />)
    } else if (experiment.status === trainingState.training && experiment.running_on_edge) {
      return (<TrainingRunningIcon className="icon" />)
    } else if (experiment.status === trainingState.inQueue || (experiment.status === trainingState.training && !experiment.running_on_edge)) {
      return (<TrainingQueuedIcon className="icon" />)
    } else if (experiment.status === trainingState.paused) {
      return <TrainingPausedIcon style={{ height: "15px" }} className="icon" />
      
    } else if (experiment.status === trainingState.stopped) {
      return <StopRoundedIcon style={{ color: "#a2a2a2", }} className="icon" />
    } else if (experiment.status === trainingState.aborted) {
      return (<WarningRoundedIcon
        style={{ color: "#ea061a" }}
        className="icon"
      />)
    }
  }
  
  console.log('EXPERIMENT-ROW', selectedCycle);

  return (
    <Box
      id={expIndex === 0 ? "first-experiment-row" : "experiment-row"}
      style={{
        paddingTop:
          props.showHeader === false && expIndex === 0
            ? "26px"
            : (props.showHeader === true && expIndex === 0? "20px" : "20px"),
      }}
      className="single-row"
      ref={experimentRef}
    >
      <Grid container justify="space-between" className="top-buttons">
        <Grid item>
          <HtmlTooltip title={statusTooltip()}>
            <Button
              style={{ borderLeft: "1px solid #E6E6E6" }}
              variant="outlined"
            >
              {renderStatus()}
              <CopyToClipboard
                text={experiment.experimentKey}
                onCopy={showCopyMessage}
              >
                <span style={{ marginLeft: "10px", alignSelf: "center" }}>
                  {experiment?.queue_no &&
                  experiment.status === trainingState.inQueue
                    ? "(" + experiment?.queue_no + ")"
                    : ""}
                  &nbsp; {experiment.name}
                  &nbsp; {experiment.experimentKey}
                </span>
              </CopyToClipboard>

              {/* <img src={"/images/gold.svg"} style={{ marginLeft: "12px" }} /> */}
            </Button>
          </HtmlTooltip>
        </Grid>

        {/* <Grid style={{ marginLeft: "auto" }} item>
                    <Sustainability value={experiment.sustainability} />
                </Grid> */
        }

        <Grid item>
          <ClapButton handleClap={handleClap} claps={claps} />
        </Grid>
      </Grid>

      <Box className="inner-view">
        <Grid container justify="flex-start">
          <Grid item className="avatar-container">
            <HtmlTooltip
              title={`${experiment?.userId?.first_name} ${experiment?.userId?.last_name}`}
            >
              <Avatar
                onClick={handleUserPopup}
                src={experiment?.userId?.profile_image}
                className="avatar"
                alt={experiment?.userId?.first_name}
              />
            </HtmlTooltip>

            <Popover
              id={userPopupId}
              open={openUserPopup}
              anchorEl={userAnchorEl}
              onClose={handleCloseUserPopup}
              elevation={0}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <UserPopup
                itsMe={false}
                profile_image={experiment?.userId?.profile_image}
                user={experiment?.userId}
                closeCallback={handleCloseUserPopup}
              />
            </Popover>
          </Grid>

          <Grid item className="details-container">
            <Grid container justify="flex-start">
              <Grid
                xs={3}
                style={{ maxWidth: "22%", alignSelf: "stretch" }}
                item
              >
                <Box paddingTop={1} pr={5} className="">
                  <Grid container>
                    <Grid xs={6} className="heading-model" item>
                      Model:
                    </Grid>
                    <Grid xs={6} className="heading-model" item>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {experiment.modelName &&
                          experiment.modelName}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={6} className="heading-model" item>
                      Category:
                    </Grid>
                    <Grid xs={6} className="heading-model" item>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {experiment.category}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid xs={6} className="heading-model" item>
                      Date:
                    </Grid>
                    <Grid xs={6} className="heading-model" item>
                      <span>
                        {formatDate(new Date(experiment.created_date))}
                      </span>
                    </Grid>
                  </Grid>
                </Box>
                
                  <Box mt={3} paddingTop={1} pr={5}>

                    {
                      subdataset &&
                      <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center">
                      <span className="heading-model">Subdataset</span>
                      </Grid>
                    }

                    { subdataset &&
                    <Box paddingTop={1} pr={5} className="">
                      <Grid container>
                          <Grid xs={7}
                            title="trainingDataSetsize"
                            style={{ overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block"
                            }}
                            className="heading-model" item>
                          Dataset size:
                        </Grid>
                        <Grid xs={4} className="heading-model" item>
                          <span
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block",
                              marginLeft: "6px",
                            }}
                          >
                            {subdataset.trainingDatasetSize}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container>
                          <Grid xs={7} className="heading-model"
                            item>
                          Classes:
                        </Grid>
                          <Grid xs={4}
                            className="heading-model"
                            title={JSON.stringify(subdataset.trainingClasses)}
                            item>
                          <div
                            style={{
                              // overflow: "hidden",
                              // textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block",
                              marginLeft: "6px",
                            }}
                          >
                              {
                                Object.entries(subdataset.trainingClasses).map(entry => {
                                      const value = entry;
                                      return (
                                        <span
                                          style={{
                                            display: "block",
                                          }}
                                          key={value[0]}>
                                              {`${value[0]}: ${value[1]}`}
                                          </span>
                                      )
                                })
                              }
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid xs={7} className="heading-model" item>
                          imageShape:
                        </Grid>
                        <Grid xs={4} className="heading-model" item>
                          <span
                            style={{
                              marginLeft: "6px",
                            }}
                          >
                            {experiment?.dataset_parameters?.image_shape}
                          </span>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid xs={7} className="heading-model" item>
                          imageType:
                        </Grid>
                        <Grid xs={4} className="heading-model" item>
                          <span
                            style={{
                              marginLeft: "6px",
                            }}
                          >
                            {experiment?.dataset_parameters?.image_type}
                          </span>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid xs={7} className="heading-model" item>
                          seed:
                        </Grid>
                        <Grid xs={4} className="heading-model" item>
                          <span
                            style={{
                              marginLeft: "6px",
                            }}
                          >
                            {experiment?.dataset_parameters?.seed ? "True" : "False"}
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    }
                    
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        marginTop: "30px",
                      }}
                    >
                      <span className="heading-model">Notes</span>
                      {experiment?.userId?.id === user.user_id && (
                        <EditObjectiveIcon className="icon" />
                      )}
                      {objectiveDisabled && objective.trim().length < 1 && (
                      <span
                        onClick={onObjectiveClick}
                        className="my-8"
                          style={{
                            fontSize: "12px",
                            color: "#9B9B9B",
                            fontStyle: "italic",
                            opacity: "0.5",
                          }}
                        >
                          Type here to help your colleagues understand which
                          problem you are facing, which models you consider,
                          which assumptions you took.
                        </span>
                      )}
                    </Grid>

                    {objectiveDisabled ? (
                      <div
                        style={{
                          wordBreak: "break-all",
                          minHeight: "80px",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          maxHeight: "225px",
                          overflow: "auto",
                        }}
                        onClick={onObjectiveClick}
                      >
                        <ReactMarkdown>{objective}</ReactMarkdown>
                      </div>
                  ) : (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <TextField
                        onClick={onObjectiveClick}
                        inputRef={objectiveRef}
                        onBlur={onObjectiveBlur}
                        fullWidth
                        focused
                        disabled={objectiveDisabled}
                        className="objective-box normal-text my-8"
                        id="standard-multiline-static"
                        label=""
                        placeholder="Type here to help your colleagues understand which
                          problem you are facing, which models you consider,
                          which assumptions you took."
                        multiline
                        minRows={8}
                        InputProps={{ disableUnderline: true }}
                        defaultValue={objective}
                        />
                        </ClickAwayListener>
                    )}
                  </Box>
                
              </Grid>
              <Grid
                xs={9}
                style={{ maxWidth: "78%", flexBasis: "78%" }}
                item
                container
                direction="column"
              >
                <Grid container justify="flex-start">
                  <Grid
                    xs={6}
                    item
                    className={`graph-box ${
                      fullscreenAccuracy ? "fullscreen-graph" : ""
                    }`}
                  >
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      className="graph-header"
                    >
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <b>{ experiment.category === "keypoint_detection" ? "PCK" : "Accuracy"}</b>
                            {
                              " is the ratio of correct predictions (both true positives and true negatives) to the total number of examined cases"
                            }
                          </React.Fragment>
                        }
                      >
                        <span className="heading">{ experiment.category === "keypoint_detection" ? "PCK" : "Accuracy"}</span>
                      </HtmlTooltip>
                      <HtmlTooltip title={`${experiment.category === "keypoint_detection" ? "PCK" : "Accuracy"} achieved with Training dataset`}>
                        <span className="values training">
                          Training:{" "}
                          <span>
                            {(+selectedEpoch?.trainingAccuracy || 0.0).toFixed(
                              2
                            )}
                          </span>
                        </span>
                      </HtmlTooltip>

                      <HtmlTooltip title={`${experiment.category === "keypoint_detection" ? "PCK" : "Accuracy"} achieved with Validation dataset`}>
                        <span className="values validation mx-8">
                          Validation:{" "}
                          <span>
                            {(
                              +selectedEpoch?.validationAccuracy || 0.0
                            ).toFixed(2)}
                          </span>
                        </span>
                      </HtmlTooltip>

                      <HtmlTooltip title="Expand Graph">
                        <Button
                          variant="outlined"
                          onClick={handleFullscreenAccuracy}
                        >
                          <FullScreenIcon
                            style={{ transform: "rotate(-45deg)" }}
                            className="icon"
                          />
                        </Button>
                      </HtmlTooltip>
                    </Grid>

                    <ResponsiveContainer
                      width={"100%"}
                      height={fullscreenAccuracy ? "100%" : 185}
                    >
                      <ComposedChart
                        onClick={chartClickHandler}
                        syncId={`experimentChart-${experiment.id}`}
                        data={experiment.aggregate_epochs}
                        // margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        margin={{ left: -20, top: 20, right: 20 }}
                      >
                        {/* <ReferenceLine ifOverflow="extendDomain" x={300} stroke="#e6e6e6" label="" /> */}
                        <XAxis
                          interval={0}
                          tick={renderEpochTick}
                          type="category"
                          tickLine={false}
                          axisLine={false}
                          height={40}
                          dataKey="epochSeq"
                        >
                          {/* <Label
                                                        height={20}
                                                        value="Epochs"
                                                        offset={2}
                                                        position="insideBottomRight"
                                                        style={{ fontSize: '80%', fill: 'rgba(0, 0, 0, 0.7)' }}

                                                    /> */}
                        </XAxis>
                        <YAxis
                          tick={{ fontSize: 10 }}
                          width={62}
                          tickFormatter={(value) => { return formatNumberToKMBT(value)
                            // if (value < 200) return (+value).toFixed(2);
                            // else return (+value).toExponential();
                          }}
                          type="number"
                          tickLine={false}
                          axisLine={false}
                        />
                        <CartesianGrid vertical={false} stroke="#e6e6e6" />
                        <Tooltip
                          cursor={{ stroke: "#3576A7", strokeWidth: 1 }}
                          content={<RenderToolTip category={experiment.category} /> }
                        />
                        <Line
                          //dot={{ strokeWidth: 0, r: 0 }}
                          dot={false}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          dataKey="trainingAccuracy"
                          stroke="#ffae15"
                        />
                        <Line
                          //dot={{ strokeWidth: 2, r: 4 }}
                          dot={false}
                          // dot={<CustomizedCycleDot />}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          // connectNulls={true}
                          dataKey="validationAccuracy"
                          stroke="#3576a7"
                        />
                        {referenceDotsAccuracy}
                        {OptimumCycleLine}
                      </ComposedChart>
                    </ResponsiveContainer>

                    {experiment.aggregate_epochs.length === 0 && (
                      <EmptyGraph status={experiment.status} type="accuracy" />
                    )}
                    {experiment.aggregate_epochs.length > 0 && (
                      <HtmlTooltip title="Epoch​ is one cycle through the full training dataset​​">
                        <div className="epoch">Epoch</div>
                      </HtmlTooltip>
                    )}
                  </Grid>
                  <Grid
                    style={{ paddingRight: "0px" }}
                    xs={6}
                    item
                    className={`graph-box ${
                      fullscreenLoss ? "fullscreen-graph" : ""
                    }`}
                  >
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      className="graph-header"
                    >
                      <HtmlTooltip title="Loss Function​">
                        <span style={{ width: "38%" }} className="heading">
                          Loss:{" "}
                          {
                            experiment?.trainingPlan?.hyperparameters
                              ?.lossFunction
                          }
                        </span>
                      </HtmlTooltip>
                      <HtmlTooltip title="Training loss">
                        <span
                          style={{ width: "21%" }}
                          className="values training"
                        >
                          T:{" "}
                          <span>
                            {(+selectedEpoch?.trainingLoss || 0.0).toFixed(2)}
                          </span>
                        </span>
                      </HtmlTooltip>
                      <HtmlTooltip title="Validation loss">
                        <span
                          style={{ width: "21%" }}
                          className="values validation mx-4"
                        >
                          V:{" "}
                          <span>
                            {(+selectedEpoch?.validationLoss || 0.0).toFixed(2)}
                          </span>
                        </span>
                      </HtmlTooltip>

                      <HtmlTooltip title="Expand Graph​">
                        <Button
                          style={{ marginLeft: "auto" }}
                          variant="outlined"
                          onClick={handleFullscreenLoss}
                        >
                          <FullScreenIcon
                            style={{ transform: "rotate(-45deg)" }}
                            className="icon"
                          />
                        </Button>
                      </HtmlTooltip>
                    </Grid>
                    <ResponsiveContainer
                      width={"100%"}
                      height={fullscreenLoss ? "100%" : 185}
                    >
                      <ComposedChart
                        onClick={chartClickHandler}
                        syncId={`experimentChart-${experiment.id}`}
                        data={experiment.aggregate_epochs}
                        margin={{ left: -20, top: 20, right: 12 }}
                      >
                        {/* <ReferenceLine ifOverflow="extendDomain" y={1} alwaysShow={true} stroke="#e6e6e6" label="actual" /> */}

                        <XAxis
                          interval={0}
                          tick={renderEpochTick}
                          type="category"
                          tickLine={false}
                          axisLine={false}
                          height={40}
                          dataKey="epochSeq"
                        >
                          {/* <Label
                                                            content={<text>Epochs</text>}
                                                            offset={2}
                                                            position="insideBottomRight"
                                                            style={{ fontSize: '80%', fill: 'rgba(0, 0, 0, 0.7)' }}
                                                        /> */}
                        </XAxis>

                        <YAxis
                          tick={{ fontSize: 10 }}
                          width={62}
                          tickFormatter={(value) => { return formatNumberToKMBT(value)

                            // if (value < 1000) return (+value).toFixed(2);
                            // else return (+value).toExponential();
                          }}
                          type="number"
                          tickLine={false}
                          axisLine={false}
                        />
                        <CartesianGrid vertical={false} stroke="#e6e6e6" />
                        <Tooltip
                          cursor={{ stroke: "#3576A7", strokeWidth: 1 }}
                          content={<RenderToolTip />}
                        />
                        <Line
                          // dot={{ strokeWidth: 2, r: 4 }}
                          dot={false}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          dataKey="trainingLoss"
                          stroke="#ffae15"
                        />
                        <Line
                          // dot={{ strokeWidth: 2, r: 4 }}
                          dot={false}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          dataKey="validationLoss"
                          stroke="#3576a7"
                        />

                        {referenceDotsLoss}
                        {OptimumCycleLine}
                      </ComposedChart>
                    </ResponsiveContainer>

                    {experiment.aggregate_epochs.length === 0 && (
                      <EmptyGraph status={experiment.status} type="loss" />
                    )}
                    {experiment.aggregate_epochs.length > 0 && (
                      <HtmlTooltip title="Epoch​ is one iteration through the full training dataset​">
                        <div style={{ marginRight: "12px" }} className="epoch">
                          Epoch
                        </div>
                      </HtmlTooltip>
                    )}
                  </Grid>
                </Grid>

                {/* Optimum Cycle details */}

                <Grid container className="optimum-cycle-head">
                  {selectedCycle?.cycleNo && (
                    <Grid item>
                      <span className="selected">
                        {" "}
                        Selected c = {selectedCycle?.cycleNo}
                      </span>
                    </Grid>
                  )}
                  {selectedCycle && optimumCycle && (
                    <Grid xs={1} style={{ textAlign: "center" }} item>
                      |
                    </Grid>
                  )}
                  {optimumCycle && (
                    <Grid item>
                      <HtmlTooltip
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Optimum cycle
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              The Optimum is calculated based on the vgap'-{experiment.category === "keypoint_detection" ? "pck" : "acc"}
                              accuracy and is represented by the green line in
                              the graph.
                            </div>
                          </div>
                        }
                      >
                        <span className="optimum">
                          Optimum c = {optimumCycle?.cycleNo}
                        </span>
                      </HtmlTooltip>
                    </Grid>
                  )}
                </Grid>

                <Grid container className="optimum-cycle" style={{ marginBottom: 0 }}>
                  <>
                    <Grid xs={2} item className="item">
                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              vgap'-{experiment.category === "keypoint_detection" ? "pck" : "acc"}
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Weighted difference of normalized vgap and
                              training {experiment.category === "keypoint_detection" ? "pck" : "accuracy"}.
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              The {experiment.category === "keypoint_detection" ? "pck" : "accuracy"} to vgap weight ratio is 50/50. Value
                              range [-0.5,0.5]. A score of -0.5 marks the best
                              performance and 0 is the outlier.
                            </div>
                          </div>
                        }
                      >
                        <Grid container className="item-container">
                          <span className="head">vgap'-{experiment.category === "keypoint_detection" ? "pck" : "acc"}</span>
                          <span className="selected-text">
                            {
                              parseFloat(selectedCycle?.v_gap_accuracy || 0).toFixed(2)
                            }
                          </span>

                          {optimumCycle && (
                            <span className="optimum-text">
                              {
                                parseFloat(
                                  optimumCycle?.v_gap_accuracy
                                ).toFixed(2)
                              }
                            </span>
                          )}
                        </Grid>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">vgap</div>
                            <div className="tooltip-sustain-sub-title">
                              vgap = absolute | validation loss - training loss|
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              It is also known as generalization gap.
                            </div>
                          </div>
                        }
                      >
                        <Grid container className="item-container">
                          <span className="head">{"vgap"}</span>
                          <span className="selected-text">
                            {(parseFloat(selectedCycle?.v_gap || 0)).toFixed(2)}
                          </span>
                          {optimumCycle && (
                            <span className="optimum-text">
                              {(parseFloat(optimumCycle?.v_gap)).toFixed(2)}
                            </span>
                          )}
                        </Grid>
                      </HtmlTooltip>
                    </Grid>

                    <Grid xs={3} item
                      style={{
                        maxWidth: "189px",
                        marginLeft: "auto",
                        flexBasis: "25%",
                      }}
                      className="item"
                    >
                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                            {experiment.category === "keypoint_detection" ? "pck" : "acc"}-flops'
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Optimal training {experiment.category === "keypoint_detection" ? "pck" : "accuracy"} in conjunction with
                              normalized flops utilization over all experiments
                              for the dataset.
                            </div>
                            <div className="tooltip-sustain-sub-title">
                            {experiment.category === "keypoint_detection" ? "pck" : "Accuracy"} to flops ratio is 80/20. Value range:
                              [1,0], where 1 is the most performant value.
                            </div>
                          </div>
                        }
                      >
                        <Grid container className="item-container">
                          <span style={{ width: "70%" }} className="head">
                          {experiment.category === "keypoint_detection" ? "pck" : "acc"}-flops'
                          </span>
                          <span
                            style={{ width: "30%" }}
                            className="selected-text"
                          >
                            {numberWithSpaces(
                              parseFloat(selectedCycle?.accuracy_flops || 0).toFixed(
                                2
                              )
                            )}
                          </span>
                          {optimumCycle && (
                            <span
                              style={{ width: "30%" }}
                              className="optimum-text"
                            >
                              {numberWithSpaces(
                                parseFloat(
                                  optimumCycle?.accuracy_flops
                                ).toFixed(2)
                              )}
                            </span>
                          )}
                        </Grid>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                            {experiment.category === "keypoint_detection" ? "pck" : "acc"}-gCO2e'
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Optimal training {experiment.category === "keypoint_detection" ? "pck" : "accuracy"} in conjunction with
                              normalized gCO2e utilization over all experiments
                              for the dataset.
                            </div>
                            <div className="tooltip-sustain-sub-title">
                            {experiment.category === "keypoint_detection" ? "pck" : "Accuracy"} to gCO2e ratio is 80/20. Value range:
                              [1,0], where 1 is the most performant value.
                            </div>
                          </div>
                        }
                      >
                        <Grid container className="item-container">
                          <span style={{ width: "70%" }} className="head">
                          {experiment.category === "keypoint_detection" ? "pck" : "acc"}-gCO2e'
                          </span>
                          <span
                            style={{ width: "30%" }}
                            className="selected-text"
                          >
                            {parseFloat(selectedCycle?.accuracy_gco2 || 0).toFixed(
                                2
                              )
                            }
                          </span>
                          {optimumCycle && (
                            <span
                              style={{ width: "30%" }}
                              className="optimum-text"
                            >
                              {parseFloat(optimumCycle?.accuracy_gco2).toFixed(
                                  2
                                )
                              }
                            </span>
                          )}
                        </Grid>
                      </HtmlTooltip>
                    </Grid>

                    <Grid xs={3} item
                      style={{
                        maxWidth: "189px",
                        marginLeft: "auto",
                        flexBasis: "25%",
                      }}
                      className="item"
                    >
                      <Grid container className="item-container">
                        <HtmlTooltip
                          title={
                            <div className="sustain-tooltip">
                              <div className="tooltip-sustain-title">
                                precision
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                precision is the number of true positive results
                                divided by the number of all positive results,
                                including those not identified correctly.
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Value range [0,1], where 1 is the most
                                performant value.
                              </div>
                            </div>
                          }
                        >
                          <span className="head">{"precision"}</span>
                        </HtmlTooltip>
                        {selectedCycle?.scores && (
                          <HtmlTooltip
                            title={
                              <div className="sustain-tooltip">
                                <div className="tooltip-sustain-title">
                                  precision
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                  precision is the number of true positive
                                  results divided by the number of all positive
                                  results, including those not identified
                                  correctly.
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                  Value range [0,1], where 1 is the most
                                  performant value.
                                </div>
                              </div>
                            }
                          >
                            <span className="selected-text">
                              {
                                parseFloat(
                                  +selectedCycle?.scores["precision"]
                                ).toFixed(2)
                              }
                            </span>
                          </HtmlTooltip>
                        )}
                        <HtmlTooltip
                          title={
                            <div className="sustain-tooltip">
                              <div className="tooltip-sustain-title">
                                precision
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                precision is the number of true positive results
                                divided by the number of all positive results,
                                including those not identified correctly.
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Value range [0,1], where 1 is the most
                                performant value.
                              </div>
                            </div>
                          }
                        >
                          <span className="optimum-text">
                            {optimumCycle?.scores &&
                              
                                parseFloat(
                                  optimumCycle?.scores["precision"]
                                ).toFixed(2)
                              }
                          </span>
                        </HtmlTooltip>
                      </Grid>

                      <Grid container className="item-container">
                        <HtmlTooltip
                          title={
                            <div className="sustain-tooltip">
                              <div className="tooltip-sustain-title">
                                recall
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                recall is the number of true positive results
                                divided by the number of all samples that should
                                have been identified as positive.
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Value range [0,1], where 1 is the most
                                performant value.
                              </div>
                            </div>
                          }
                        >
                          <span className="head">{"recall"}</span>
                        </HtmlTooltip>
                        {selectedCycle?.scores && (
                          <HtmlTooltip
                            title={
                              <div className="sustain-tooltip">
                                <div className="tooltip-sustain-title">
                                  recall
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                  recall is the number of true positive results
                                  divided by the number of all samples that
                                  should have been identified as positive.
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                  Value range [0,1], where 1 is the most
                                  performant value.
                                </div>
                              </div>
                            }
                          >
                            <span className="selected-text">
                              {
                                parseFloat(
                                  selectedCycle?.scores["recall"] || 0
                                ).toFixed(2)
                              }
                            </span>
                          </HtmlTooltip>
                        )}
                        <HtmlTooltip
                          title={
                            <div className="sustain-tooltip">
                              <div className="tooltip-sustain-title">
                                recall
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                recall is the number of true positive results
                                divided by the number of all samples that should
                                have been identified as positive.
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Value range [0,1], where 1 is the most
                                performant value.
                              </div>
                            </div>
                          }
                        >
                          <span className="optimum-text">
                            {optimumCycle?.scores &&
                                parseFloat(
                                  optimumCycle?.scores["recall"]
                                ).toFixed(2)
                              }
                          </span>
                        </HtmlTooltip>
                      </Grid>

                      <Grid container className="item-container">
                        <HtmlTooltip
                          title={
                            <div className="sustain-tooltip">
                              <div className="tooltip-sustain-title">
                                F1 score
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                F1 score is the harmonic mean of precision and
                                recall.
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Value range [0,1], where 1 is the most
                                performant value.
                              </div>
                            </div>
                          }
                        >
                          <span className="head">{"F1 score"}</span>
                        </HtmlTooltip>
                        {selectedCycle?.scores && (
                          <HtmlTooltip
                            title={
                              <div className="sustain-tooltip">
                                <div className="tooltip-sustain-title">
                                  F1 score
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                  F1 score is the harmonic mean of precision and
                                  recall.
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                  Value range [0,1], where 1 is the most
                                  performant value.
                                </div>
                              </div>
                            }
                          >
                            <span className="selected-text">
                              {parseFloat(
                                  selectedCycle?.scores["f1_score"]
                                ).toFixed(2)
                              }
                            </span>
                          </HtmlTooltip>
                        )}
                        <HtmlTooltip
                          title={
                            <div className="sustain-tooltip">
                              <div className="tooltip-sustain-title">
                                F1 score
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                F1 score is the harmonic mean of precision and
                                recall.
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Value range [0,1], where 1 is the most
                                performant value.
                              </div>
                            </div>
                          }
                        >
                          <span className="optimum-text">
                            {optimumCycle?.scores &&
                                parseFloat(
                                  optimumCycle?.scores["f1_score"]
                                ).toFixed(2)
                              }
                          </span>
                        </HtmlTooltip>
                      </Grid>
                    </Grid>

                    <Grid xs={3} item className="item">
                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">Flops</div>
                            <div className="tooltip-sustain-sub-title">
                              Computation utilization in floating point
                              operations up to the selected cycle
                            </div>
                          </div>
                        }
                      >
                        <Grid container className="item-container">
                          <span style={{ width: "35px" }} className="head">
                            {"flops"}
                          </span>
                          <span className="selected-text">
                            {nFormatter(selectedCycle?.flopsutilised, 2)}F
                          </span>
                          {optimumCycle && (
                            <span className="optimum-text">
                              {nFormatter(optimumCycle?.flopsutilised, 2)}F
                            </span>
                          )}
                        </Grid>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">gCO2e</div>
                            <div className="tooltip-sustain-sub-title">
                              Environmental footprint up to the selected cycle
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              gCO2e = number of grams of CO2 emissions with the
                              same global warming potential as one gram of
                              another greenhouse gas.
                            </div>
                          </div>
                        }
                      >
                        <Grid container className="item-container">
                          <span style={{ width: "35px" }} className="head">
                            {"gCO2e"}
                          </span>
                          <span
                            style={{ minWidth: "30%" }}
                            className="selected-text"
                          >
                            {numberWithSpaces(
                              parseFloat(+selectedCycle?.gco2 || 0).toFixed(2)
                            )}
                          </span>
                          {optimumCycle && (
                            <span
                              style={{ minWidth: "30%" }}
                              className="optimum-text"
                            >
                              {numberWithSpaces(
                                parseFloat(optimumCycle?.gco2).toFixed(2)
                              )}
                            </span>
                          )}
                        </Grid>
                      </HtmlTooltip>
                    </Grid>
                  </>
                </Grid>

               { experiment.category === "keypoint_detection" && <Grid container className="optimum-cycle" style={{ marginTop: 0 }}>
                  <>
                    
                 { selectedCycle?.scores?.mean_absolute_error && <Grid xs={3} item
                      style={{
                        maxWidth: "189px",
                        marginLeft: 0,
                        flexBasis: "25%",
                      }}
                      className="item"
                    >
                      
                      <Grid container className="item-container">
                        <HtmlTooltip
                          title={
                              <div className="sustain-tooltip">
                                <div className="tooltip-sustain-title">
                                  MAE
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                Mean Absolute Error (MAE) is the average of the absolute differences
                                between predicted and actual values. It measures the accuracy of a model
                                by quantifying the average magnitude of errors in a set of predictions,
                                without considering their direction.
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                The value range for MAE is [0, ∞), where 0 indicates the best
                                performance, meaning no error between predictions and actual values.
                                </div>
                              </div>
                            }
                        >
                          <span className="head">{"MAE"}</span>
                        </HtmlTooltip>
                        
                          <HtmlTooltip
                            title={
                              <div className="sustain-tooltip">
                                <div className="tooltip-sustain-title">
                                  MAE
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                Mean Absolute Error (MAE) is the average of the absolute differences
                                between predicted and actual values. It measures the accuracy of a model
                                by quantifying the average magnitude of errors in a set of predictions,
                                without considering their direction.
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                The value range for MAE is [0, ∞), where 0 indicates the best
                                performance, meaning no error between predictions and actual values.
                                </div>
                              </div>
                            }
                          >
                            <span className="selected-text">
                              {
                                parseFloat(
                                  +selectedCycle?.scores["mean_absolute_error"]
                                ).toFixed(2)
                              }
                            </span>
                          </HtmlTooltip>
                        <HtmlTooltip
                          title={
                              <div className="sustain-tooltip">
                                <div className="tooltip-sustain-title">
                                  MAE
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                Mean Absolute Error (MAE) is the average of the absolute differences
                                between predicted and actual values. It measures the accuracy of a model
                                by quantifying the average magnitude of errors in a set of predictions,
                                without considering their direction.
                                </div>
                                <div className="tooltip-sustain-sub-title">
                                The value range for MAE is [0, ∞), where 0 indicates the best
                                performance, meaning no error between predictions and actual values.
                                </div>
                              </div>
                            }
                        >
                          <span className="optimum-text">
                            {optimumCycle?.scores &&
                              
                                parseFloat(
                                  optimumCycle?.scores["mean_absolute_error"]
                                ).toFixed(2)
                              }
                          </span>
                        </HtmlTooltip>
                      </Grid>

                    
                    </Grid>}
                  </>
                </Grid>}

                <Grid item className="control-box">
                  <Grid
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    container
                    style={{
                      marginTop: "-4px",
                    }}
                  >
                    <Grid
                      style={{ maxWidth: "20%", flexBasis: "20%" }}
                      xs={3}
                      className="color-container"
                      item
                    >
                      <div className="normal-text">
                        Epoch &nbsp;
                        <span className="cycle-no">
                          {selectedEpoch?.epochSeq || 0}
                        </span>
                        &nbsp; of
                        <span className="rounds total-cycles">
                          {experiment.cycles * experiment.epochs}
                        </span>
                      </div>
                    </Grid>
                    <HtmlTooltip
                      title={
                        experiment.status === trainingState.completed ||
                        experiment.status === trainingState.stopped
                          ? "Training Time taken for Completion​"
                          : "Training Time Left​"
                      }
                    >
                      <Grid
                        style={{ maxWidth: "20%", flexBasis: "20%" }}
                        xs={3}
                        item
                        className="color-container"
                        alignSelf="center"
                      >
                        {experiment.status === trainingState.completed ||
                        experiment.status === trainingState.paused ||
                        experiment.status === trainingState.stopped ? (
                          <ClockIcon
                            className="icon"
                            style={{ width: "24px", height: "24px" }}
                          />
                        ) : (
                          <ClockCountIcon
                            className="icon"
                            style={{ width: "24px", height: "24px" }}
                          />
                        )}
                        <span className="est-time">
                          {timeLeft ? convertSecondsToDay(timeLeft) : "~"}
                        </span>
                      </Grid>
                    </HtmlTooltip>

                    <Grid
                      style={{ maxWidth: "20%", flexBasis: "20%" }}
                      xs={3}
                      className="color-container"
                      item
                    >
                      <div className="buttons-list">
                        <HtmlTooltip title="Start Experiment​">
                          <span>
                            <Button
                              className="first-child"
                              aria-label="delete"
                              onClick={() =>
                                handleExperimentStatus(trainingState.inQueue)
                              }
                              disabled={
                                !(experiment.status === trainingState.paused) ||
                                !(experiment?.userId?.id === user.user_id)
                              }
                            >
                              {changingStatus ? (
                                <CircularProgress size={10} />
                              ) : (
                                <PlayArrowRoundedIcon
                                  style={{
                                    filter: !(
                                      experiment.status === trainingState.paused
                                    )
                                      ? "grayscale(2) brightness(1.5)"
                                      : "",
                                  }}
                                  className="icon"
                                />
                              )}
                            </Button>
                          </span>
                        </HtmlTooltip>

                        <HtmlTooltip title="Pause Experiment​">
                          <span>
                            <Button
                              title="Pause Experiment​"
                              onClick={() =>
                                handleExperimentStatus(trainingState.paused)
                              }
                              disabled={
                                !(
                                  experiment.status ===
                                    trainingState.training ||
                                  experiment.status === trainingState.inQueue
                                ) || !(experiment?.userId?.id === user.user_id)
                              }
                            >
                              {changingStatus ? (
                                <CircularProgress size={10} />
                              ) : (
                                <PauseRoundedIcon
                                  style={{
                                    filter: !(
                                      experiment.status ===
                                        trainingState.training ||
                                      experiment.status ===
                                        trainingState.inQueue
                                    )
                                      ? "grayscale(2) brightness(1.5)"
                                      : "",
                                  }}
                                  className="icon"
                                />
                              )}
                            </Button>
                          </span>
                        </HtmlTooltip>

                        <HtmlTooltip title="Stop Experiment​">
                          <span>
                            <Button
                              title="Stop Experiment​"
                              className="last-child"
                              onClick={() =>
                                handleExperimentStatus(trainingState.stopped)
                              }
                              disabled={
                                !(
                                  experiment.status ===
                                    trainingState.training ||
                                  experiment.status === trainingState.inQueue
                                ) || !(experiment?.userId?.id === user.user_id)
                              }
                            >
                              {changingStatus ? (
                                <CircularProgress size={10} />
                              ) : (
                                <StopRoundedIcon
                                  style={{
                                    filter: !(
                                      experiment.status ===
                                        trainingState.training ||
                                      experiment.status ===
                                        trainingState.inQueue
                                    )
                                      ? "grayscale(2) brightness(1.5)"
                                      : "",
                                  }}
                                  className="icon"
                                />
                              )}
                            </Button>
                          </span>
                        </HtmlTooltip>
                      </div>

                      <div className="buttons-list">
                        <HtmlTooltip
                          title={
                            <div className="sustain-tooltip">
                              <div className="tooltip-sustain-title">
                                Training is
                                {experiment.status === trainingState.training && " running"}
                                {experiment.status === trainingState.completed && " completed"}
                                {experiment.status === trainingState.paused && " paused"}
                                {experiment.status === trainingState.stopped && " stopped"}
                                {experiment.status === trainingState.aborted && " aborted"}
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Current gCO2e/kWh is
                                {
                                  experiment?.sustainability_info?.current_carbon_intensity <= experiment?.sustainability_info?.moving_avg_carbon_intensity ?
                                    " below or equal to " : " above "
                                }
                                the 7-day average
                                threshold
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                7-day avg: {experiment?.sustainability_info?.moving_avg_carbon_intensity} gCO2e/kWh
                              </div>
                              <div className="tooltip-sustain-sub-title">
                                Currently: {experiment?.sustainability_info?.current_carbon_intensity} gCO2e/kWh
                              </div>
                            </div>
                          }
                        >
                          <span>
                            <Button
                              className="first-child last-child"
                              style={{ marginLeft: "12px" }}
                              aria-label="delete"
                              onClick={() =>
                                handleEcoTraining(
                                  experiment?.run_sustainable_training
                                )
                              }
                              disabled={
                                experiment.status === trainingState.completed ||
                                !(experiment?.userId?.id === user.user_id)
                              }
                            >
                              {changingStatus ? (
                                <CircularProgress size={10} />
                              ) : (
                                <EcoTrainingIcon
                                  // style={{
                                  //   filter:
                                  //     !experiment?.run_sustainable_training
                                  //       ? "grayscale(0.8) brightness(1.5)"
                                  //       : "",
                                  // }}
                                  active={experiment?.run_sustainable_training}
                                  className="icon"
                                  />
                                  
                              )}
                            </Button>
                          </span>
                        </HtmlTooltip>
                      </div>
                    </Grid>

                    <Grid
                      style={{ maxWidth: "20%", flexBasis: "20%" }}
                      xs={2}
                      className="color-container"
                      item
                    >
                      <Grid container direction="column">
                        <span
                          style={{
                            textAlign: "center",
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflowX: "auto",
                          }}
                          className="est-time"
                        >
                          <HtmlTooltip title="Flops utilized​">
                            <span>
                              {nFormatter(experiment.flopsutilised, 2)}F
                            </span>
                          </HtmlTooltip>

                          {experiment.status !== trainingState.completed && (
                            <>
                              &nbsp;of&nbsp;
                              <HtmlTooltip title="Estimated Flops needed to complete Experiment​​">
                                <span>
                                  {nFormatter(experiment.estimatedflops, 2) !==
                                  "~ "
                                    ? "~ "
                                    : ""}
                                  {nFormatter(experiment.estimatedflops, 2)}F
                                </span>
                              </HtmlTooltip>
                            </>
                          )}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid
                      style={{ maxWidth: "20%", flexBasis: "20%" }}
                      xs={2}
                      className="color-container"
                      item
                    >
                      <span
                        style={{
                          textAlign: "center",
                          width: "auto",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                        className="est-time"
                      >
                        <Sustainability
                          expStatus={experiment.status}
                          estimated={experiment.estimated_gco2}
                          current={experiment.sustainability}
                          value={
                            experiment.status !== trainingState.completed
                              ? experiment.estimated_gco2
                              : experiment.sustainability
                          }
                        />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>

                <Inference experiment={experimentData} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Box>


      <Grid container justify="space-between" className="bottom-buttons">
          <div
            id={
              expIndex === 0
                ? "first-experiment-left-buttons"
                : "experiment-buttons-left"
            }
            className="buttons-list"
          >
          
          {
            experiment.category !== "keypoint_detection" &&
            <HtmlTooltip title="Confusion Matrix">
              <Button
                className={showConfusionMatrix ? "selected" : ""}
                onClick={() => {
                  setShowConfusionMatrix(!showConfusionMatrix);
                  setShowTrainingPlan(false);
                  setShowCodeView(false);
                  setShowComments(false);
                }}
              >
                <ConfusionMatrixIcon className="icon" />
                {showConfusionMatrix && (
                  <span className="title">Confusion Matrix</span>
                )}
              </Button>
            </HtmlTooltip>
          }
          

            <HtmlTooltip title="Training Plan">
              <Button
                className={showTrainingPlan ? "selected" : ""}
                onClick={() => {
                  setShowTrainingPlan(!showTrainingPlan);
                  setShowConfusionMatrix(false);
                  setShowCodeView(false);
                  setShowComments(false);
                }}
              >
                <TrainingPlanIcon className="icon" />
                {showTrainingPlan && (
                  <span className="title">Training Plan</span>
                )}
              </Button>
            </HtmlTooltip>
            <HtmlTooltip title="Discussion">
              <Button
                className={showComments ? "selected" : ""}
                onClick={() => {
                  setShowComments(!showComments);
                  setShowConfusionMatrix(false);
                  setShowCodeView(false);
                  setShowTrainingPlan(false);
                }}
              >
                <CommentsIcon className="icon" />
                {showComments && <span className="title">Discussion</span>}
              </Button>
            </HtmlTooltip>
            <HtmlTooltip title="Model Code">
              <Button
                className={showCodeView ? "selected" : ""}
                onClick={() => {
                  setShowCodeView(!showCodeView);
                  setShowConfusionMatrix(false);
                  setShowComments(false);
                  setShowTrainingPlan(false);
                }}
              >
                <CodeViewIcon
                  style={{
                    width: "16px",
                    height: "auto",
                    filter: showCodeView
                      ? "saturate(0%) brightness(1000%) contrast(100%)"
                      : "",
                  }}
                  className="icon"
                />
                {showCodeView && <span className="title">Code</span>}
              </Button>
            </HtmlTooltip>
          </div>

          <div
            id={
              expIndex === 0
                ? "first-experiment-right-buttons"
                : "experiment-buttons-right"
            }
            className="buttons-list"
          >
            <HtmlTooltip
              title={
                selectedCycle?.cycleNo
                  ? "Download Model, Weights, Training Plan​"
                  : "Please choose a cycle in the graph."
              }
            >
              <Button
                onClick={
                  !selectedCycle?.cycleNo
                    ? downloadWeightsHandleDisabled
                    : downloadWeightsHandle
                }
              >
                {!downloading ? (
                  <DownloadModelIcon
                    className="icon"
                    style={{
                      filter: !selectedCycle?.cycleNo
                        ? "grayscale(2) brightness(1.5)"
                        : "",
                    }}
                  />
                ) : (
                  <CircularProgress size={10} />
                )}
                <span style={{ marginLeft: "6px", fontStyle: "normal" }}>
                  ({downloads})
                </span>
              </Button>
            </HtmlTooltip>

            <HtmlTooltip title="Archive Experiment​">
              <Button
                onClick={handleArchiveExperiment}
                className="icon-button"
                color="primary"
                variant="outlined"
              >
                <ArchiveIcon className="icon" />
              </Button>
            </HtmlTooltip>
          </div>
        </Grid>

      {(showComments ||
        showCodeView ||
        showTrainingPlan ||
        showConfusionMatrix) && (
        <Box className="bottom-sheets">
          <Comments
            Me={user}
            experiment={experiment.id}
            expanded={showComments}
          />
          <CodeView experiment={experiment.id} expanded={showCodeView} />
          <ConfusionMatrix
            cycleNo={selectedCycle?.cycleNo}
            experiment={experiment.id}
            confusionMatrix={selectedCycle?.confusion_matrix}
            expanded={showConfusionMatrix}
          />
          <TrainingPlan
            experiment={{
              augmentation_parameters: experiment.augmentation_parameters,
              hyperparameters: experiment.hyperparameters,
              training_parameters: experiment.training_parameters,
              dataset_parameters: experiment.dataset_parameters,
            }}
            expanded={showTrainingPlan}
          />
        </Box>
      )}

      <Portal>
        <Snackbar
          open={downloadStarted}
          autoHideDuration={6000}
          onClose={() => setDownloadStarted(false)}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            variant="filled"
            severity="success"
            iconMapping={{
              success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
            }}
            onClose={() => setDownloadStarted(false)}
          >
            {`Model is downloading at epoch ${selectedEpoch?.epochIndex - 1}`}
          </Alert>
        </Snackbar>
        {copied && (
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="success"
            >
              Experiment ID successfully copied
            </Alert>
          </Snackbar>
        )}

        {outOfFlops && (
          <Snackbar
            open={outOfFlops}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="warning"
            >
              You don’t have enough FLOPS to start the experiment.
            </Alert>
          </Snackbar>
        )}
      </Portal>
    </Box>
  );
};

export default Row;

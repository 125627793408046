import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Portal,
  Snackbar,
} from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { CopyIcon } from "../../Icon";
import SaveDataset from "../../SaveDataset";
import { API_URL, GlobalContext } from "../../../Context/GlobalState";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";


import axios from "axios";
import "./style.css";
import { Alert } from "@material-ui/lab";
import { parseJsonString } from "../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import NoteBook from "../DataAnalysis/NoteBook";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    marginTop: "12px",
    flexDirection: "column",
    "& a": {
      color: "var(--primary)",
      textDecoration: "none",
    },
    "& pre": {
      fontFamily:
        "Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L , Monaco, Courier New ,Courier, monospace",
      whiteSpace: "pre-wrap",
      overflowX: "auto",
      tabWidth: 4,
      background: "rgba(0,0,0, 0.6)",
      color: "white",
      padding: 20,
      borderRadius: 5,
    },
    "& ul": {
      margin: 0,
      padding: 0,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  flexContainerVertical: {
    alignItems: "flex-start",
  },
}));

const DataView = (props) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const [dataset, setDataset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const showCopyMessage = (e) => {
    setCopied(true);
    console.log("Copied");
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };


  const getDataset = () => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    if (!token) {
      // window.location = "/login";
      const index = location.pathname.indexOf('/details');
      const redirectPath = location.pathname.substring(0, index + '/details'.length);

      history.push({
        pathname: "/",
        state: {
          redirect_to: `${redirectPath}/data`,
        },
      });
      return false;
    }
    const config = {
      method: "get",
      url: `${API_URL}/dataset/${props.dataset}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        console.log("DATA", data);
        const dataset_meta = data.dataset_meta ? JSON.parse(data.dataset_meta) : null;

        console.log("DATA", dataset_meta);
        const userDataset = dataset_meta && {
          dataset_key: data.dataset_key,
          count: dataset_meta.count,
          dataset_type: data.dataset_type,
          groups: dataset_meta.groupedDefectData || dataset_meta.grouped_defect_data,
          data_samples: parseJsonString(data.data_samples) || [],
          description: data?.description,
          label_density: dataset_meta?.label_density,
          unique_data_items_count: dataset_meta?.unique_data_items_count,
          data_items_label_count: dataset_meta?.data_items_label_count,
          total: dataset_meta.total,

        };

        setDataset({ ...userDataset });
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getDataset();
  }, []);

  return (
    <>
      <Box className={`${classes.root} competition-dataview`}>
        {/* <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <h3
            className="dataset-title"
            style={{
              marginBottom: "0px",
              fontWeight: "500",
            }}
          >
            Dataset ID
          </h3>

          <h3
            className="dataset-title"
            style={{
              marginBottom: "0px",
              fontWeight: "500",
              color: "#707070",
              paddingLeft: "6px",
            }}
          >
            5628xg4
          </h3>
        </Box> */}
        {/* <Box
          className="details"
          dangerouslySetInnerHTML={{ __html: props.data_details }}
        /> */}

        <Grid container justify="space-between">
                    <Grid item>
                        {/* <h3 className="dataset-title">Dataset</h3> */}
                    </Grid>
                    <Grid style={{ zIndex: 2 }} item>
                        <div>
                        
                            <span className="dataset-id-title">Dataset ID</span>
                            {!dataset && <CircularProgress color="inherit" size={12} style={{ marginLeft: '8px', color: "var(--primary)"}} />}
                            <CopyToClipboard text={dataset && dataset.dataset_key}
                                onCopy={() => showCopyMessage()}>
                                <Button className="copy-dataset-id">
                                    <span className="dataset-id-value">{ dataset && dataset.dataset_key }</span>
                                    <CopyIcon color="primary" className="icon" />
                                </Button>
                            </CopyToClipboard>
                            
                        </div>
                    </Grid>
                </Grid>
        <Box style={{ position: "relative", top: "-24px"}}>
          {dataset && <SaveDataset dataset={dataset} />}
        </Box>

        <NoteBook />

      </Box>

      
      <Portal>
        {copied && (
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              variant="filled"
              iconMapping={{
                success: (
                  <CheckCircleOutlineRoundedIcon style={{ fontSize: 24 }} />
                ),
              }}
              severity="success"
            >
              Dataset ID successfully copied
            </Alert>
          </Snackbar>
        )}
      </Portal>
    </>
  );
};

export default DataView;

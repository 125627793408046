import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./style.css";
import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
} from "@material-ui/core";

import MySubmissionsRow from "./MySubmissionsRow";


import { API_URL } from "../../../Context/GlobalState";
import axios from "axios";
import useStickyHeader from "../../../utils/useStickyHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "#",
    description: "",
    leftAlign: true,
  },
  // { id: 'logo', numeric: false, disablePadding: false, label: '', description: "" },
  {
    id: "experiment_id",
    numeric: false,
    disablePadding: false,
    label: "Experiment",
    description: "Experiment ID",
    leftAlign: true,
  },
  {
    id: "cycle",
    numeric: false,
    disablePadding: false,
    label: "Cycle",
    description: "Experiment submitted at cycle",
    leftAlign: true,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    description: "Date of Submission​",
    leftAlign: true,
  },
  {
    id: "accuracy",
    numeric: false,
    disablePadding: false,
    label: "Accuracy",
    description: "",
    leftAlign: true,
  },
  {
    id: "loss",
    numeric: false,
    disablePadding: false,
    label: "Loss",
    description: "",
    leftAlign: true,
  },
  {
    id: "model_parameters",
    numeric: false,
    disablePadding: false,
    label: "Size (Params / Bytes)",
    description: "Size of the model in parameters and in bytes",
    leftAlign: true,
  },
  // {
  //   id: "running_score",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Test dataset 1",
  //   description: "",
  // },
  // {
  //   id: "running_score",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Test dataset 2",
  //   description: "",
  // },
  {
    id: "inference_flopsutilised",
    numeric: false,
    disablePadding: false,
    label: "Energy Consumption (FLOP / Rel %)",
    description: "Average energy consumption per inference in FLOP and relative energy consumption compared to the baseline (leading model)",
  },
  {
    id: "gco2",
    numeric: true,
    disablePadding: false,
    label: "CO2e",
    description: "Energy consumption in grams or milligrams of CO2 equivalent",
  },
  // {
  //   id: "flops",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Computation Budget",
  //   description: "Provided Computation by Host per Competition​",
  // },
  {
    id: "submit",
    numeric: true,
    disablePadding: false,
    label: "Final Submission",
    description: "Submitted to Leaderboard",
  }
];

function EnhancedTableHead(props) {
  const { classes, numSelected, rowCount, scoreFormula, final_score } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          // if (headCell.id === "final_score" && !final_score) {
          //   return null
          // }
          return (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "default"}
              className={headCell.id}
              style={{}}
            >
              {headCell.id === "code" ? (
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        Benchmarked Model results​ <br />
                        <br />
                      </div>
                      <div>
                        At the end of the competition
                        <br />
                        the Model which make it into
                        <br />
                        the money will be shared
                        <br />
                        with the Host. ​
                      </div>
                    </React.Fragment>
                  }
                >
                  <span>{headCell.label}</span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${
                    headCell.id === "score" ? scoreFormula : headCell.description
                  }`}
                >
                  <span>{headCell.label}</span>
                </Tooltip>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const MySubmissions = (props) => {
  const classes = useStyles();
  const {dataset} = props
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("score");
  const [page, setPage] = useState(0);
  const [leaderboard, setLeaderBoard] = useState([]);
  const [next, setNext] = useState(null);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { componentRef, isSticky, isBottom, coords } = useStickyHeader();


  // FInal Submision vars
  const [loadingSubmission1, setLoadingSubmission1] = useState(false);
  const [loadingSubmission2, setLoadingSubmission2] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [success, setSuccess] = useState(false);
  const [submission1Disabled, setSubmission1Disabled] = useState(true);
  const [submission2Disabled, setSubmission2Disabled] = useState(true);
  const expIdRef1 = useRef(null)
  const expIdRef2 = useRef(null)
  const cycleRef1 = useRef(null)
  const cycleRef2 = useRef(null)


  const getLeaderBoardEntries = (page) => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");

    let headers = {}
    if (token) {
      headers = {
        Authorization: `Token ${token}`,
      }
    }

    const config = {
      method: "get",
      url: `${API_URL}/inference/${props.dataset}/${
        page ? "?page=" + page : ""
      }`,
      headers,
    };
    axios(config)
      .then((res) => {
        const data = res.data;
        console.log(data);
        setLeaderBoard([
          ...leaderboard,
          ...data.results]);
        setNext(data.next);
        setLoading(false);
        setError(null);

      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (leaderboard.length === 0) {
      getLeaderBoardEntries();
    }

    const allZero = Object.values(coords).every(value => value === 0);

    if (leaderboard.length > 0 && next) {
      getLeaderBoardEntries(page);
    }
  }, [page]);

  useEffect(() => {
    console.log("Scroll-LEADERBOARD", isBottom, coords);
    const allZero = Object.values(coords).every(value => value === 0);
    if ((isBottom || allZero) && next) {
      const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      setPage(params.page);
    }
  }, [isBottom, next]);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, leaderboard.length - page * rowsPerPage);

  const handleClick = (e, id) => {
    console.log("Clicked", e, id);
  };

  console.log("pages-LEADERBOARD", leaderboard.length, isBottom, next, coords);
  console.log("###LEADERBOARD", props.score_formula_display);

  const getFinalScoreStatus = () => {
    if (leaderboard.length > 0) {
      if (leaderboard[0]?.final_score) {
          return true
        }
    } 
    return false
  }




  // --------------------------------------------
  // Final Submissions
  // --------------------------------------------

  const getSubmission = () => {
    setLoadingSubmission1(true);
    setLoadingSubmission2(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
    method: "get",
    url: `${API_URL}/submit/final/${dataset}/`,
    headers: {
        Authorization: `Token ${token}`,
    },
    };
    axios(config)
    .then((res) => {
        const data = res.data;
        console.log("FinalSubmissions-get", data?.results, dataset);
        if (data?.results) {
            setSubmissions([...data.results])
        }
        
        setLoadingSubmission1(false);
        setLoadingSubmission2(false);

    })
    .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
        setError("Please make sure your credentials are correct.")
        } else {
        
        setError("Make sure you are connected to the internet.")
        }
        setLoadingSubmission1(false);
        setLoadingSubmission2(false);
    });
};


const submitSubmission1 = (e) => {

    console.log(e.currentTarget, expIdRef1.current.value)
    let data = {
        "experiment": expIdRef1.current.value,
        "cycle": cycleRef1.current.value
    };
    setLoadingSubmission1(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const submission = submissions.length > 0 ? `${submissions[0].id}/` : ''
    const config = {
        method: submissions.length > 0 ? "PUT" : "POST",
        url: `${API_URL}/submit/final/${dataset}/${submission}`,
        headers: {
            Authorization: `Token ${token}`,
        },
        data: data
    };
    axios(config)
    .then((res) => {
        const data = res.data;
        console.log("FinalSubmissions-get", data, dataset);
        // setCompetition({ ...data })
        // updateCompetition({...data})
        setSuccess(true)
        setError(null)
        setLoadingSubmission1(false);
        getSubmission()
    })
    .catch((error) => {
        console.log("CATCH", error.response.data);
        if (error.response && error.response.data) {
            setError(error.response.data)
        } else {
            setError("Make sure you are connected to the internet.")
        }
        setLoadingSubmission1(false);
    });


};


const handleSubmission1Disabled = () => {
    const exp  = expIdRef1.current.value.length
    const cycle = cycleRef1.current.value.length
    console.log((exp < 1), cycle)
    setSubmission1Disabled((exp < 1 || cycle < 1))
    
}


const submitSubmission2 = (e) => {

    console.log(e.currentTarget, expIdRef2.current.value)
    let data = {
        "experiment": expIdRef2.current.value,
        "cycle": cycleRef2.current.value
    };
    setLoadingSubmission2(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const submission = submissions.length > 1 ? `${submissions[1].id}/` : ''
    const config = {
        method: submissions.length > 1 ? "PUT" : "POST",
        url: `${API_URL}/submit/final/${dataset}/${submission}`,
        headers: {
            Authorization: `Token ${token}`,
        },
        data: data
    };



    axios(config)
    .then((res) => {
        const data = res.data;
        console.log("FinalSubmissions-get", data, dataset);
        // setCompetition({ ...data })
        // updateCompetition({...data})
        setSuccess(true)
        setError(null)
        setLoadingSubmission2(false);
        getSubmission()
    })
    .catch((error) => {
        console.log("CATCH", error.response.data);
        if (error.response && error.response.data) {
            setError(error.response.data)
        } else {
            setError("Make sure you are connected to the internet.")
        }
        setLoadingSubmission2(false);
    });


};


const handleSubmission2Disabled = () => {
    const exp  = expIdRef2.current.value.length
    const cycle = cycleRef2.current.value.length
    console.log((exp < 1), cycle)
    setSubmission2Disabled((exp < 1 || cycle < 1))
}

  useEffect(() => {
    getSubmission()
  }, []);


  const deleteSubmission = (submission) => {
    const newSubs = submissions.filter((item) => (item.id !== submission))
    setSubmissions([...newSubs]);
    // console.log("###deleteSubmission", submission, newSubs);

  }

  const addSubmission = (submission) => {
    if (submission?.id) {
      setSubmissions([...submissions, submission]);
      // console.log("###addSubmission", submission);
    }
  }

  return leaderboard.length > 0 ? (
    <Box pt={1}>
      {/* <Alert severity="info" color="info">
                    <div>This leaderboard is calculated with approximately 12% of the test data.</div>
                    The final results will be based on the other 88%, so the final standings may be different.
                </Alert> */}
      <div class="competition-leaderboard__legends">
        {/* <div class="competition-leaderboard__legend competition-leaderboard__legend--in-the-money">
        Price contenders
        </div> */}
        {/* <div class="competition-leaderboard__legend description">
          This leaderboard is calculated with approximately {props.test_data_percentage}% of the test data. The final results will be based on the other {props.final_data_percentage}%, so the final standings may be different.
        </div> */}
        {/*<div class="competition-leaderboard__legend competition-leaderboard__legend--silver">Silver</div>
                    <div class="competition-leaderboard__legend competition-leaderboard__legend--bronze">Bronze</div> */}
      </div>

      <TableContainer
        TableContainer
        ref={componentRef}
        style={{ marginTop: "8px", maxHeight: "calc(100vh - 262px)" }}
        className="table-conatainer"
      >
        <Table stickyHeader className="my-submissions-table" aria-label="sticky simple table">
          <EnhancedTableHead
            scoreFormula={props.score_formula_display}
            final_score={ getFinalScoreStatus() }
            classes={classes}
            numSelected={selected.length}
            rowCount={leaderboard.length}
          />
          <TableBody>

            {
              /// no sort on client side 
              //stableSort(leaderboard, getComparator(order, orderBy))
              leaderboard.map(
              (row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <MySubmissionsRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    totalEntries={leaderboard.length}
                    position={index + 1}
                    baseEnergyConsumption={leaderboard[0].flopsutilised}
                    totalPrizes={props.prizes.length}
                    selected={isItemSelected}
                    is_private={props.is_private}
                    key={row.id}
                    labelId={labelId}
                    dataset={props.dataset}
                    row={row}
                    finalSubmission={submissions.filter((item) => (item.cycle === row.cycle && item.experiment_key === row.experiment_key))}
                    totalFinalSubmissions={submissions.length}
                    hostCompany={props?.host_company}
                    addSubmission={addSubmission}
                    deleteSubmission={deleteSubmission}
                  />
                );
              }
              )
            }

            
            {
              loading ?
                <TableRow>
                  <TableCell className="empty-td" colSpan={7}>
                    <Grid container justify="center" alignItems="center"><CircularProgress /></Grid>
                  </TableCell>
                </TableRow>
                : null}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell className="empty-td" colSpan={7}>
                  &nbsp;
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <Grid container justify="center" alignItems="center">
      {loading ? <CircularProgress /> : <div>No entries found</div>}
    </Grid>
  );
};

export default MySubmissions;

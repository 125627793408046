import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Grid, IconButton, Popover } from '@material-ui/core';

import './style.css'
import logo from '../../assets/logo-beta.png'
import logoFull from '../../assets/logo-full-beta.png'
import {
    CylinderIcon, EngineIcon, IndustrialXrayIcon, SnowIcon, DocumentationIcon, LabelsIcon,
    PreprocessingIcon, DataSetBuilderIcon, ExperimentIcon, DataSetIcon, TrophyIcon, DiscordIcon
} from '../Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { ToggleButton } from '@material-ui/lab';
import { Link, useLocation, useParams } from 'react-router-dom';
import { API_URL, GlobalContext, SERVER_URL } from '../../Context/GlobalState';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';

import avatar from './../../assets/pexelsPixabay220453318c14b7.png'
import CompeteImg from './../../assets/icons/Compete@4x.png'
import XrayImg from './../../assets/icons/Xray@4x.png'
import DatasetsImg from './../../assets/icons/DataSets@4x.png'
import DatasetBuilderImg from './../../assets/icons/data_set_builder@2x.png'
import DocumentationImg from './../../assets/icons/docs.svg'
import ProcessingImg from './../../assets/icons/Preprocessing@4x.png'
import LabelsImg from './../../assets/icons/Labels@4x.png'
import ExperimentsImg from './../../assets/icons/traceblox_experiments@2x.png'
import DiscordImg from './../../assets/icons/discord-icon-svgrepo-com.svg'
import UserPopup from '../UserPopup';




const pagesList = [
    { title: "Competitions", target: "_self", path: "/competitions", icon: <img alt="" src={CompeteImg} /> },
    { title: "Experiments", target: "_self", path: "/experiments", icon: <img alt="" src={ExperimentsImg} /> },
    // { title: "Dataset Builder", target: "_self", path: "/dataset-builder", icon: <img style={{ height: '16px'}} src={DatasetBuilderImg}  /> },
    // { title: "Datasets", target: "_self", path: "/datasets", icon: <img style={{ height: '16px'}} src={DatasetsImg} /> },
    { title: "Documentation", target: "_blank", path: "//docs.tracebloc.io", icon: <img alt="" src={DocumentationImg}  /> },
]

const Sidebar = (props) => {
    const { sidebar, openSideBar, user, getUserInfo } = useContext(GlobalContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const { open } = sidebar;
    let { pathname: path } = useLocation();
    
    useEffect(() => {
        const token = localStorage.getItem('_key_usr_tkn')
        if (!user && token) {
            getUserInfo();
        }
    }, [user, getUserInfo]);



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const openUser = Boolean(anchorEl);
      const id = openUser ? 'simple-popover' : undefined;

    console.log('user', user)
    return (
        <Grid className="sidebar-links" container>
            <a className="logo" href="/">
                <img style={{ height: open ? '36px' : '47px' }} src={open ? logoFull : logo} alt="tracebloc" />
            </a>
            <div className="toggle-button">
                <div className="line"></div>
                <ToggleButton
                    className={`${open ? 'toggle-close' : 'toggle-open'}`}
                    selected={open} onChange={() => openSideBar(!open)}
                    value="closed">«</ToggleButton>
            </div>

            <div className='menu-items'>
            {
                pagesList.map(page => {
                    return (
                        <Link
                            key={page.path}
                            to={page.path}
                            target={page.target}
                            className={`button${page.path === path ? " button-selected" : ''}`}>
                            <Tooltip title={`${page.title}`}>
                                <div className="img-container">{page.icon}</div>
                            </Tooltip>
                            
                            <span className="label">{page.title}</span>
                        </Link>
                    )
                })
            }
            </div>

            <Grid item style={{ marginTop: 'auto', marginBottom: '60px', flexBasis: 'auto', padding: '16px 16px 0px' }}>
                <a style={{
                        position: 'fixed',
                        margin: 'auto',
                        marginBottom: "2px",
                        marginLeft: '6px',
                        bottom: "60px",
                    }}
                    key={"discord"}
                    href={"https://discord.gg/suXKUCAMfA"}
                    target="_blank"
                    rel="noreferrer"
                    // className={'avatar'}
                    // className={`button${"#" === path ? " button-selected" : ''}`}
                >
                    <Tooltip title={"Discord"}>
                        <div className="img-container">
                            <img style={{ width: "20px", filter: "grayscale(0.8)" }} src={DiscordImg} />
                        </div>
                    </Tooltip>
                    {/* <span className="label">Discord</span> */}
                </a>
                {
                    user && 
                    <>
                        <Tooltip title={`To ${user.first_name} ${user.last_name} Profile`}>
                            <Avatar
                                onClick={handleClick}
                                src={user.profile_image}
                                className={'avatar'}
                                    alt={`${user.first_name} ${user.last_name}`} />
                        </Tooltip>
                        
                        <Popover
                            id={id}
                            open={openUser}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            >
                            <UserPopup profile_image={user.profile_image} itsMe={true} user={user} closeCallback={handleClose} />
                        </Popover>
                    </>
                    
                }

            </Grid>
        </Grid>
    );
}

export default Sidebar;
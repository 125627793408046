import {
  Avatar,
  Grid,
  Collapse,
  TableCell,
  TableRow,
  Tooltip as MuiToolTip,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";

import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';

import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { convertToMilligramAndGram, formatNumberToKMBT, nFormatter, numberWithSpaces, printNumberSign, timeSince } from "utils/format";
// import ExperimentRow from "./ExperimentRow";
import { API_URL } from "Context/GlobalState";
import imagePlaceHolder from "assets/avatar.png";
import logo from 'assets/logo-full.png';
import ExperimentRow from "Components/ExperimentsList/ExperimentRow";

// { id, name, date, members, rating };
function LeaderBoardRow(props) {
  const {
    row,
    labelId,
    onClick,
    totalPrizes,
    position,
    totalEntries,
    dataset,
    is_private,
    baseEnergyConsumption,
    scoreFormula,
    ...rowProps
  } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [team, setTeam] = useState(null);
  const [experiment, setExperiment] = useState(null);

  const updated_date = new Date(row.submit_date);

  useEffect(() => {
    if (is_private) {
      console.log("is_private", is_private);
      if (row.team) {
        setTeam({
          ...row.team,
          company_logo: imagePlaceHolder,
          dataScientists: row.team.data_scientists,
          experiments_count: row.experiments_count,
          flops_used: row.flops_used || 0,
          flops: row.competition_flops,
        });
      } else {
        setTeam({
          name: `${row?.data_scientist?.first_name || ""} ${row?.data_scientist?.last_name || ""}`,
          company_logo: imagePlaceHolder,
          dataScientists: [{ ...row?.data_scientist }],
          experiments_count: row.experiments_count,
          flops_used: row.flops_used || 0,
          flops: row.competition_flops,
        });
      }
    } else {
      setTeam({
        ...row.company,
        dataScientists: row.participants,
        experiments_count: row.company_experiments,
        flops_used: row.flops_used || 0,
        flops: row.competition_flops,
      });
    }
  }, []);

  console.log("ROW-2-team", team);
  const rowOpen = () => {
    setOpen(!open);
    getExperimentDetails()
    onClick();
  };

  const getExperimentDetails = () => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    
    const config = {
      method: "get",
      url: `${API_URL}/experiment-details/${dataset}/${row.experiment}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        setExperiment({ ...data[0] });
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };


  console.log("###LEADER", props.hostCompany)
  //id, name, notebook, members, score, entries, last
  return (
    <React.Fragment>
      <TableRow
        style={{
          cursor:
          typeof(row.experiment) === "object"
              ? "default"
              : "pointer",
        }}
        {...rowProps}
        onClick={typeof(row.experiment) !== "object" ? rowOpen : null}
      >
        {/* id */}
        <TableCell id={labelId} style={{ width: "45px" }}>
          {position <= totalPrizes && (
            <div
              className="money-bar"
              style={{
                opacity: (totalEntries - (position - 1)) / totalEntries,
              }}
            ></div>
          )}
          {position}
        </TableCell>

        {/* Logo */}
        {/* <TableCell id={labelId} style={{ width: "88px" }}>
          <MuiToolTip title={`${row?.company?.name}`}>
            <div className="img-container">
              <img src={team?.company_logo} />
            </div>
          </MuiToolTip>
        </TableCell> */}

        {/* Team */}
        <TableCell
          style={{
            textAlign: "left",
            width: "180px",
          }}
          id={labelId}
        >
          <div style={{
            display: "flex",
            alignItems: "center"

          }}>
            <span>{team?.name}</span>
            {
            team?.is_tracebloc && <Tooltip title='This team includes members of the tracebloc, thus not eligible for prize money.'>
              <img style={{ width: "12px", marginLeft: "5px" }} alt="tracebloc" src={logo} width={12} />
            </Tooltip>
            }
            
          
          {
            !team?.is_tracebloc && team?.is_host && <Tooltip title='This team includes members of the organizer, thus not eligible for prize money.'>
                {/* <BusinessRoundedIcon style={{ fontSize: '12px', marginLeft: "5px" }} /> */}
                <img style={{ width: "12px", marginLeft: "5px" }} alt={props?.hostCompany?.name} src={props?.hostCompany?.company_logo} width={12} />
            </Tooltip>
          }
          </div>

          
          
        </TableCell>

        {/* Members */}
        <TableCell
          style={{
            width: "50px",
          }}
        >
          <AvatarGroup
            spacing={10}
            classes={{ avatar: "avatar-small" }}
            max={row.members}
          >
            {team?.dataScientists &&
              team.dataScientists.map((user, index) => {
                return (
                  <Avatar
                    key={`member-${index}`}
                    className="avatar-small"
                    alt={`${user.first_name} ${user.last_name}`}
                    src={user.profile_image}
                    title={`${user.first_name} ${user.last_name}`}
                  />
                );
              })}
          </AvatarGroup>
        </TableCell>



         {/*Running Score */}
         <TableCell>
          <MuiToolTip
            title={`Score calculated using: ${scoreFormula}`}
          >
            <div style={{ fontWeight: "bold" }}>{(row.running_score && parseFloat(row.running_score).toFixed(4)) || ""}</div>
          </MuiToolTip>
        </TableCell>


        {/*Final Score: test dataset 2 */}
        {/* {
          
          <TableCell>
              <MuiToolTip
                title={`Score calculated using ${scoreFormula}`}>
                <div>{row?.final_score > -1 ? parseFloat(row.final_score).toFixed(4) : "~"}</div>
              </MuiToolTip>
            </TableCell>
        } */}


        {/*Final Score: test dataset 2 */}

        {
          row?.final_score > -1 &&
          <TableCell>
              <MuiToolTip
                title={`Score calculated using: ${scoreFormula}`}>
                <div>{parseFloat(row.final_score).toFixed(4) || ""}</div>
              </MuiToolTip>
            </TableCell>
        }

        {/* model_parameters */}
        <TableCell>
          <MuiToolTip
            title={`Size of the model in parameters and in bytes`}
          >
          <div style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            width: '100%'
          }}>
            <div style={{ marginRight: 8, width: '50%', textAlign: 'right'}}>
            {(row?.model_parameters && formatNumberToKMBT(row?.model_parameters || 0)) || ""}
            </div>
            
            <div style={{ marginLeft: 8, width: '50%', textAlign: 'left'}}>
            {( row?.model_size && formatNumberToKMBT(row?.model_size || 0) + "B") || ""}
            </div>
          </div>
          </MuiToolTip>
        </TableCell>

       

        {/* inference_flopsutilised */}
        <TableCell
          // style={{
          //   textAlign: "center",
          // }}
        >
          
          <div style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            width: '100%'
          }}>
            <div style={{ marginRight: 8, width: '50%', textAlign: 'right'}}>
              {
               (row?.inference_flopsutilised && Number.parseInt(row?.inference_flopsutilised || 0).toExponential(2).replace(/e\+/g, ' e').replace(/e-/g, ' e-')) || ""
              }
            </div>
            
            <div style={{ marginLeft: 8, width: '50%', textAlign: 'left'}}>
              { 
                position === 1 ? "-" :
                (row?.inference_flopsutilised && numberWithSpaces(printNumberSign(
                    (
                      ((row?.inference_flopsutilised - baseEnergyConsumption) / baseEnergyConsumption) * 100
                    ).toFixed(1).replace(/\.0$/, '')
                  ) + " %")) || ""
              }
            </div>
          </div>
          
        </TableCell>


        {/* inference_flopsutilised_relative */}
        {/* <TableCell
          // style={{
          //   textAlign: "center",
          // }}
        >
          { 
            position === 1 ? "-" :
            numberWithSpaces(printNumberSign(
                (
                  ((row?.inference_flopsutilised - baseEnergyConsumption) / baseEnergyConsumption) * 100
                ).toFixed(1).replace(/\.0$/, '')
              ) + " %")
          }
        </TableCell> */}
        

        {/*gco2 */}
        {
          
          <TableCell>
              <MuiToolTip
                title={`co2e`}>
                <div>{ (row.inference_gco2 && numberWithSpaces(convertToMilligramAndGram(parseFloat(row.inference_gco2)))) || ""}</div>
              </MuiToolTip>
            </TableCell>
        }

        {/* Entries */}
        <TableCell
          // style={{
          //   textAlign: "center",
          // }}
        >
          {team?.total_submissions_count }
        </TableCell>

        {/* Flops Utilization */}
        <TableCell
          style={{
            width: "200px",
          }}
        >
          <MuiToolTip
            title={
              <React.Fragment>
                <div>Flops utilized {nFormatter(team?.flops_used, 2)}F​</div>
                <div>Remaining available Flops {nFormatter((team?.flops - team?.flops_used), 2)}F</div>
              </React.Fragment>
            }
          >
            <div className="tflops-container">
              <div className="tflops-progress-text">
                {nFormatter(team?.flops_used, 2)}F of{" "}
                {nFormatter(team?.flops, 2)}F utilized
              </div>
              <div
                className="tflops-progress"
                style={{
                  width: `${(team?.flops_used / team?.flops) * 100 || 0}%`,
                  border: `1px solid #${ team?.flops_used > 0 ? "4bbdcc" : "c7d2d5"}`
                }}
              />
            </div>
          </MuiToolTip>
        </TableCell>
        {/* Last */}
        <TableCell>
          {row.submit_date ? timeSince(
            new Date(
              updated_date.toLocaleString("en-US", {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
            )
          ) : "~"}
        </TableCell>
        {/* Code */}
        {/* <TableCell>{"~"}</TableCell> */}
      </TableRow>

      {/* 
                ////// Expansion ////////////////
                /////\\\\\\\\\\\\\\///////////////
             */}
      <TableRow>
        <TableCell
          style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
        >
          <Collapse
            className="experiment-container"
            in={typeof(row.experiment) !== "object" && open} // PLEASE UNCOMMENT WHEN PERMISSIONS IMPLEMENTED
            timeout="auto"
            unmountOnExit
          >
            {
              experiment && (
                <ExperimentRow
                  key={experiment.id}
                  expIndex={0}
                  experimentData={experiment}
                  // unArchiveExperiment={handleArchiveExperiment}
                  showHeader={false}
                // archiveExperiment={handleArchiveExperiment}
                />
              )
            }

            { !experiment && loading &&
               <Grid container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            }

          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default LeaderBoardRow;

import { Box, Divider, Table, TableBody, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./style.css";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import DocumentationIcon from "./../../../assets/documentationIcon.png";
import PlayIcon from "./../../../assets/playIcon.png";
import RectPlayIcon from "./../../../assets/rectPlayIcon.png";
import DataSetRow from "Components/ExperimentsList/DataSetRow";
import ArchivedDataSetRow from "Components/ExperimentsList/ArchivedExperiments/DataSetRow";

const subBoxContainerStyle = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
};
const subHeadingStyle = {
  color: "#3576A7",
  fontSize: "12px",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "8px",
};
const subDescriptionStyle = {
  color: "#707070",
  fontSize: "12px",
  fontStyle: "italic",
  marginTop: "24px",
};

export default function Experiments(props) {
  const [hasExperiment, setHasExperiment] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);

  const showArchivedExperiments = (e) => {
    setShowArchived(!showArchived)
  }
  return (
    <div className="competition-experiments">
      {loading === false && hasExperiment === false && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "48px",
            marginBottom: "110px",
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              color: "#707070",
            }}
          >
            Ready to start competing?
          </Typography>

          <Typography
            style={{
              fontSize: "12px",
              color: "#707070",
            }}
          >
            Check out these helpful resources to train a model on our platform
            and submit to the leaderboard
          </Typography>
          <Box
            style={{
              marginTop: "61px",
              display: "flex",
            }}
          >
            <Box
              style={{
                width: "174px",
              }}
            >
              <Box
                component={"a"}
                href="https://colab.research.google.com/drive/1N00idtpoaq1lk9OJE6g4bMqd8o-Qex2C?usp=sharing"
                target="_blank"
                style={subBoxContainerStyle}>
                {/* <PlayCircleOutlineIcon
                  style={{ fontSize: "32px", color: "#266B9A" }}
                /> */}
                <img src={PlayIcon} alt="playIcon" height={"31.75px"} />
                <Typography style={subHeadingStyle}>Training</Typography>
              </Box>
              <Typography style={subDescriptionStyle}>
                Start an experiment with Colab: connect your Colab notebook and
                start training your model right away
              </Typography>
            </Box>

            <Divider
              flexItem
              orientation="vertical"
              variant="inset"
              style={{
                marginRight: "59.5px",
              }}
            />
            <Box
              style={{
                width: "174px",
              }}
            >
              <Box
                component={"a"}
                href="http://docs.tracebloc.io/"
                target="_blank"
                style={subBoxContainerStyle}>
                <img src={DocumentationIcon} alt="docIcon" height={"31.75px"} />
                <Typography style={subHeadingStyle}>Documentation</Typography>
              </Box>
              <Typography style={subDescriptionStyle}>
                Need some guidance before your dive in? Check out our
                documentation for step-by-step instructions on how to train a
                model on our platform
              </Typography>
            </Box>

            <Divider
              style={{
                marginRight: "59.5px",
              }}
              flexItem
              orientation="vertical"
              variant="inset"
            />
            <Box
              style={{
                width: "174px",
              }}
            >
              <Box
                component={"a"}
                href="#"
                target="_blank"
                style={subBoxContainerStyle}>
                <img src={RectPlayIcon} alt="rectPlayIcon" height={"31.75px"} />
                <Typography style={subHeadingStyle}>Video Tutorial</Typography>
              </Box>
              <Typography style={subDescriptionStyle}>
                Prefer a visual walkthrough? Watch our video tutorial to learn
                how to train a model on our platform
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            // width: "100%",
            // justifyContent: "center",
            marginRight: "32px",
            marginTop: "19px",
          }}
        >
          <Table stickyHeader className="table" aria-label="simple table">
            <TableBody>
              { !showArchived ?
                <DataSetRow
                  hover
                  onClick={(event) => { }}
                  showArchived={showArchivedExperiments}
                  role="checkbox"
                  aria-checked={true}
                  tabIndex={-1}
                  datasetIndex={0}
                  selected={true}
                  key={props.dataset}
                  archiveDataset={() => { }}
                  labelId={`enhanced-table-checkbox-${0}`}
                  row={{ ...props, id: props.dataset }}
                  showHeader={false}
                  hasExperiment={setHasExperiment}
                  expLoading={setLoading}
                /> :
                <ArchivedDataSetRow
                  hover
                  onClick={(event) => { }}
                  showArchived={showArchivedExperiments}
                  role="checkbox"
                  aria-checked={true}
                  tabIndex={-1}
                  datasetIndex={0}
                  selected={true}
                  key={props.dataset}
                  archiveDataset={() => { }}
                  labelId={`enhanced-table-checkbox-${0}`}
                  row={{ ...props, id: props.dataset }}
                  showHeader={false}
                  hasExperiment={setHasExperiment}
                  expLoading={setLoading}
                />
              }
            </TableBody>
          </Table>
        </Box>
      }
    </div>
  );
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Paper,
  TextField,
  CircularProgress,
  Popover,
  IconButton,
  Tooltip as MuiToolTip,
  Typography,
  Portal,
  Snackbar,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import PauseRoundedIcon from "@material-ui/icons/PauseRounded";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ReactMarkdown from "react-markdown";
import Tooltip from "recharts/lib/component/Tooltip";

import "./style.css";
import {
  ArchiveIcon,
  CheckIcon,
  ClapIcon,
  ClockCountIcon,
  ClockIcon,
  CodeIcon,
  CodeViewIcon,
  CommentIcon,
  CommentsIcon,
  ConfusionMatrixIcon,
  DownloadIcon,
  DownloadModelIcon,
  EditIcon,
  EditObjectiveIcon,
  FullScreenIcon,
  MenuIcon,
  NotificationCheckIcon,
  ShareTreeIcon,
  TrainingPausedIcon,
  TrainingPlanIcon,
  TrainingQueuedIcon,
  TrainingRunningIcon,
  TrainingStoppedIcon,
  UnarchiveIcon,
} from "Components/Icon";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LineChart,
} from "recharts";
import Comments from "./Comments";
import CodeView from "./CodeView";
import axios from "axios";
import { API_URL, GlobalContext } from "Context/GlobalState";
import {
  convertSecondsToDay,
  formatDate,
  nFormatter,
  numberWithCommas,
} from "utils/format";
import aucImg from "assets/icons/auc@2x.png";
import rocImg from "assets/icons/roc@2x.png";
import matrixImg from "assets/icons/matrix@2x.png";
import trainingPlanImg from "assets/icons/training_plan@2x.png";
import commentsImg from "assets/icons/comments@2x.png";
import codeviewImg from "assets/icons/codeview@2x.png";
import downloadImg from "assets/icons/download@2x.png";
import menuImg from "assets/icons/menu@2x.png";
import treeImg from "assets/icons/tree@2x.png";
import UserPopup from "Components/UserPopup";
import useOnScreen from "utils/useOneScreen";
import TrainingPlan from "./TrainingPlan";
import ConfusionMatrix from "./ConfusionMatrix";
import { trainingState } from "utils";
import { withStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import ClapButton from "Components/ClapButton";
import EmptyGraph from "./EmptyGraph";

const renderToolTip = (props) => {
  const { payload, label } = props;
  let ids = payload && payload.map((o) => o.dataKey);
  let filtered =
    payload &&
    payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));
  console.log("entry", filtered[0]?.payload.epochNo);
  return (
    <Paper className="defect-bar-tooltip" variant="outlined">
      {filtered &&
        filtered.map((entry, index) => {
          return (
            <span className={entry.name} key={`item-${entry.name}`}>
              {entry.name}: {(+entry.value).toFixed(2)}
            </span>
          );
        })}
      <div className="bold-700-12 label-margin">
        Epoch: {filtered[0]?.payload.epochNo}
      </div>
    </Paper>
  );
};

const options = { threshold: 0.4 };

const renderCycleTick = (tickProps, experiment) => {
  const { x, y, payload, visibleTicksCount } = tickProps;
  const { epochs, cycles } = experiment;
  const { value, index, offset } = payload;
  const epochNo = index + 1;
  const epochPerCycle = epochs;
  const cycleNo = epochNo / epochPerCycle;

  if (epochNo % epochPerCycle === 0) {
    const pathX = Math.floor(x);
    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text
          style={{ fontSize: 10, textAnchor: "middle", fill: "rgb(10,10,10)" }}
          x={1}
          y={1}
          textAnchor="middle"
        >{`C${cycleNo}`}</text>
        {/* <path d={`M${1},${-10}v${-15}`} stroke="grey" /> */}
      </g>
    );
  }

  return null;
};

const renderEpochTick = (tickProps) => {
  const { x, y, payload, visibleTicksCount } = tickProps;
  const { value, index } = payload;
  let margin = 5;
  if (visibleTicksCount <= 80) {
    margin = 5;
  } else if (visibleTicksCount <= 100) {
    margin = 10;
  } else if (visibleTicksCount <= 180) {
    margin = 20;
  }
  const epochNo = index + 1;
  // console.log("renderEpochTick", payload, index % 5, 'visibleTicksCount', visibleTicksCount)

  // console.log("renderEpochTick-index", epochNo, tickProps, index, index % 5)

  if (index === 0) {
    return (
      <text
        style={{ fontSize: 10, textAnchor: "middle", fill: "rgb(102,102,102)" }}
        x={x}
        y={y + 10}
        textAnchor="middle"
      >{`${index}`}</text>
    );
  } else if (index % margin === 0 && visibleTicksCount - 4 > index) {
    return (
      <text
        style={{
          margin: "1px",
          fontSize: 10,
          textAnchor: "middle",
          fill: "rgb(102,102,102)",
        }}
        x={x}
        y={y + 10}
        textAnchor="middle"
      >{`${index}`}</text>
    );
  } else if (epochNo === visibleTicksCount) {
    return (
      <text
        style={{
          margin: "1px",
          fontSize: 10,
          textAnchor: "middle",
          fill: "rgb(102,102,102)",
        }}
        x={x}
        y={y + 10}
        textAnchor="middle"
      >{`${index}`}</text>
    );
  }
  return null;
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(MuiToolTip);

const Row = (props) => {
  const { user } = useContext(GlobalContext);
  const { experimentData, expIndex } = props;
  const [experiment, setExperiment] = useState({
    ...experimentData,
    aggregate_epochs: [
      ...(experimentData.aggregate_epochs.length > 0
        ? [
            {
              created_date: "2021-12-15T09:05:56.368786Z",
              cycleNo: 1,
              epochNo: 0,
              status: 3,
              trainingAccuracy: null,
              trainingLoss: null,
              updated_date: "2021-12-15T09:44:39.907192Z",
              validationAccuracy: null,
              validationLoss: null,
            },
          ]
        : []),
      ...experimentData.aggregate_epochs,
    ],
  });
  const [fullscreenAccuracy, setFullscreenAccuracy] = useState(false);
  const [fullscreenLoss, setFullscreenLoss] = useState(false);
  const [objectiveDisabled, setObjectiveDisabled] = useState(true);
  const [showComments, setShowComments] = useState(false);
  const [showConfusionMatrix, setShowConfusionMatrix] = useState(false);
  const [showCodeView, setShowCodeView] = useState(false);
  const [showTrainingPlan, setShowTrainingPlan] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [selectedCycle, setSelectedCycle] = useState({});
  const [selectedEpoch, setSelectedEpoch] = useState({});
  const [downloading, setDownloading] = useState(false);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [error, setError] = useState(null);
  const [claps, setClaps] = useState(0);
  const [flashCycle, setFlashCycle] = useState(false);
  const objectiveRef = useRef(null);
  const [objective, setObjective] = useState("");
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const experimentRef = useRef();
  const isVisible = useOnScreen(experimentRef, options);
  let cancelExpToken, cancelTimeToken;

  // console.log("SINGLE-EXPERIEMNT", experiment, experiment.id,  user);
  const handleFullscreenAccuracy = useCallback(() => {
    setFullscreenAccuracy(!fullscreenAccuracy);
  });

  const handleFullscreenLoss = () => {
    setFullscreenLoss(!fullscreenLoss);
  };

  const onObjectiveClick = (event) => {
    setObjectiveDisabled(!(experiment?.userId?.id === user.user_id));
    // objectiveRef.current.focus();
  };

  const onObjectiveBlur = (event) => {
    setObjective(event.target.value);

    const token = localStorage.getItem("_key_usr_tkn");

    const config = {
      method: "PATCH",
      url: `${API_URL}/experiments/${experiment.id}/`,
      data: { objective: event.target.value },
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
      });
  };

  const handleClickAway = (event) => {
    setObjectiveDisabled(true);
    //console.log("handleClickAway-Objective", objectiveRef.current.value);
  };

  const getExperimentDetails = () => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    //Check if there are any previous pending requests
    if (typeof cancelExpToken != typeof undefined) {
      cancelExpToken.cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    // http://127.0.0.1:8000/experiment-details-ping/154/365/?updated_date=2021-12-02T13:20:46Z
    cancelExpToken = axios.CancelToken.source();
    const config = {
      method: "get",
      url: `${API_URL}/experiment-details/${experiment.datasetId_id}/${experiment.id}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
      cancelToken: cancelExpToken.token, //Pass the cancel token to the current request
    };

    axios(config)
      .then((res) => {
        const data = res.data;

        // add zero epoch only if there are real epochs returned from the server.
        if (data[0].aggregate_epochs.length > 0) {
          const experimentData = {
            ...data[0],
            aggregate_epochs: [
              {
                created_date: "2021-12-15T09:05:56.368786Z",
                cycleNo: 1,
                epochNo: 0,
                status: 3,
                trainingAccuracy: null,
                trainingLoss: null,
                updated_date: "2021-12-15T09:44:39.907192Z",
                validationAccuracy: null,
                validationLoss: null,
              },
              ...data[0].aggregate_epochs,
            ],
          };

          setExperiment({ ...experimentData });
          setSelectedEpoch({
            ...experimentData.aggregate_epochs[
              experimentData.aggregate_epochs.length - 1
            ],
            epochIndex: experimentData.aggregate_epochs.length,
          });

          if (experimentData.aggregatecycles.length > 0) {
            const epochNo = experimentData.aggregate_epochs.length - 1;
            const userSelectedCycle = isCycleSelectable(epochNo);
            if (userSelectedCycle) {
              const payload = {
                ...userSelectedCycle,
                scores: userSelectedCycle.scores,
              };

              setSelectedCycle({ ...payload });
            } else {
              setSelectedCycle({});
            }
          }
        }

        setClaps(data[0].claps);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    let intervalDetails, intervalTime;
    // && [trainingState.inQueue, trainingState.training].includes(experiment.status)
    if (isVisible) {
      intervalDetails = setInterval(() => {
        getExperimentUpdates();
        // The logic of changing counter value to come soon.
      }, 8000);
    } else {
      clearInterval(intervalDetails);
    }
    return () => {
      clearInterval(intervalDetails);
    };
  }, [isVisible, experiment]);

  // console.log("AAAA-LATEST", experiment.id, selectedEpoch, experiment)

  const getExperimentUpdates = () => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    //Check if there are any previous pending requests
    if (typeof cancelExpToken != typeof undefined) {
      cancelExpToken.cancel("Operation canceled due to new request.");
    }
    const epoch_updated_date =
      experiment.aggregate_epochs.length > 0
        ? experiment.aggregate_epochs[experiment.aggregate_epochs.length - 1]
            .updated_date
        : "";
    const aggregate_cycle_updated_date =
      experiment.aggregatecycles.length > 0
        ? experiment.aggregatecycles[experiment.aggregatecycles.length - 1]
            .updated_date
        : "";
    cancelExpToken = axios.CancelToken.source();
    const config = {
      method: "get",
      url: `${API_URL}/experiment-details-ping/${experiment.datasetId_id}/${experiment.id}/?epoch_updated_date=${epoch_updated_date}&aggregate_cycle_updated_date=${aggregate_cycle_updated_date}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      cancelToken: cancelExpToken.token, //Pass the cancel token to the current request
    };

    axios(config)
      .then((res) => {
        let data = res.data[0];
        const updated_aggregate_epochs = data.updated_aggregate_epochs;

        // add zero epoch only if there are real epochs returned from the server.

        let aggregate_epochs = experiment.aggregate_epochs;

        data.updated_aggregate_epochs.forEach((element, index) => {
          const updateIndex = aggregate_epochs.findIndex((item) => {
            return (
              element.cycleNo === item.cycleNo &&
              element.epochNo === item.epochNo
            );
          });

          if (updateIndex > 0) {
            aggregate_epochs[updateIndex] = { ...element };
          } else {
            aggregate_epochs.push(element);
          }
          // updated_aggregate_epochs.splice(index, 1)
        });

        const experimentDataLatest = {
          ...experiment,
          ...data,
          aggregate_epochs: [
            ...(aggregate_epochs.length > 0 && aggregate_epochs[0].epochNo !== 0
              ? [
                  {
                    created_date: "2021-12-15T09:05:56.368786Z",
                    cycleNo: 1,
                    epochNo: 0,
                    status: 3,
                    trainingAccuracy: null,
                    trainingLoss: null,
                    updated_date: "2021-12-15T09:44:39.907192Z",
                    validationAccuracy: null,
                    validationLoss: null,
                  },
                ]
              : []),
            ...aggregate_epochs,
          ],
          aggregatecycles: [
            ...experiment.aggregatecycles,
            ...data.updated_aggregate_cycles.filter((aggregateCycle) => {
              const object = experiment.aggregatecycles.find(
                (item) => aggregateCycle.cycleNo === item.cycleNo
              );
              return typeof object === "undefined" ? true : false;
            }),
          ],
        };

        if (experimentDataLatest.aggregate_epochs.length > 0) {
          setExperiment({ ...experimentDataLatest });
          setSelectedEpoch({
            ...experimentDataLatest.aggregate_epochs[
              experimentDataLatest.aggregate_epochs.length - 1
            ],
            epochIndex: experimentDataLatest.aggregate_epochs.length,
          });

          if (experimentDataLatest.aggregatecycles.length > 0) {
            const epochNo = experimentDataLatest.aggregate_epochs.length - 1;
            const userSelectedCycle = isCycleSelectable(epochNo);
            if (userSelectedCycle) {
              const payload = {
                ...userSelectedCycle,
                scores: userSelectedCycle.scores,
              };

              setSelectedCycle({ ...payload });
            } else {
              setSelectedCycle({});
            }
          }
        }
        setTimeLeft(data.time_left);
        setClaps(data.claps);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    setObjective(experiment.objective);
    setClaps(experiment.claps);
    if (experiment.time_left > 0) {
      setTimeLeft(experiment.time_left);
    }

    if (experiment.aggregate_epochs.length > 0) {
      setSelectedEpoch({
        ...experiment.aggregate_epochs[experiment.aggregate_epochs.length - 1],
        epochIndex: experiment.aggregate_epochs.length, // 1 added to fix the value of Epoch x from y.
      });
    }
    if (experiment.aggregatecycles.length > 0) {
      const aggregateCycle =
        experiment.aggregatecycles[experiment.aggregatecycles.length - 1];
      const payload = { ...aggregateCycle, scores: aggregateCycle.scores };
      setSelectedCycle(payload);
    }
  }, []);

  useEffect(() => {
    if (timeLeft <= 0 || experiment.status !== trainingState.training) {
      return;
    }
    // count down timer for Time est.
    const timer = () => setTimeLeft(timeLeft - 1);

    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [timeLeft]);




  const downloadWeightsHandle_2 = async () => {
    setDownloading(true);
    setDownloadStarted(true); // used for displaying the download alert
    const token = localStorage.getItem("_key_usr_tkn");
    
    try {
      const response = await axios({
        url: `${API_URL}/downloadmodel/${experiment.id}/${selectedCycle.cycleNo}/`,
        method: 'GET',
        responseType: 'blob', // Important
        headers: {
          Authorization: `Token ${token}`,
        }
      });
      // setDownloads(downloads + 1);
      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Set the download attribute with a filename
      link.setAttribute('download', `${experiment.id}_${selectedCycle.cycleNo}.zip`); // Replace with your desired file name and extension

      // Append to the DOM and trigger click event to start download
      document.body.appendChild(link);
      link.click();

      // Cleanup and remove the link
      link.parentNode.removeChild(link);
      setDownloading(false);
      setError(null);
    } catch (error) {
      console.error('Error downloading the file', error);
      if (error.response) {
        setError("Please make sure your credentials are correct.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
      setDownloading(false);
    }
  };


  const downloadWeightsHandle = (e) => {
    console.log("downloadWeightsHandle", selectedCycle);
    setDownloading(true);
    setDownloadStarted(true); // used for displaying the download alert
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/getdownloadtoken/${experiment.id}/${selectedCycle.cycleNo}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        //const blob = res.data
        console.log(res.data);
        let a = document.createElement("a");
        a.href = `${API_URL}/downloadmodelfile/${res.data}/`;
        a.target = "_blank";
        a.click();
        //console.log("DATA", data);
        setDownloading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setDownloading(false);
      });
  };

  const downloadWeightsHandleDisabled = (e) => {
    setFlashCycle(true);
    setTimeout(() => setFlashCycle(false), 500);
  };

  const getCycleInfo = (e) => {
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      // url: `${API_URL}/cycle/${experiment.id}/${selectedCycle.cycleNo}/`,
      url: `${API_URL}/cycle/${394}/${1}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        //const blob = res.data
        console.log(res.data);
        setDownloading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        // setDownloading(false)
      });
  };

  const chartClickHandler = (e) => {
    if (e === null || e.activeTooltipIndex === 0) return;
    if (experiment.aggregate_epochs.length > 0) {
      setSelectedEpoch({
        ...experiment.aggregate_epochs[e.activeTooltipIndex],
        epochIndex: e.activeTooltipIndex + 1,
      });
    }
    if (
      experiment.aggregatecycles.length > 0 &&
      experiment.aggregate_epochs.length > 0
    ) {
      const epochNo = e.activeTooltipIndex;

      const findSelectedCycle = isCycleSelectable(epochNo);
      if (findSelectedCycle) {
        const payload = {
          ...findSelectedCycle,
          scores: findSelectedCycle.scores,
        };
        console.log("CONFUSION-payload", payload);
        setSelectedCycle(payload);
      } else {
        setSelectedCycle({});
      }
    }
  };

  const isCycleSelectable = (epochIndex) => {
    const { epochs, cycles } = experiment;
    const epochNo = epochIndex;
    // const epochPerCycle = epochs;
    // return (epochNo % epochPerCycle === 0)

    const findSelectedCycle = experiment.aggregatecycles.find(
      (aggregateCycle) => {
        const selectEpochNo = aggregateCycle.last_epoch;
        return epochNo === selectEpochNo;
      }
    );
    if (findSelectedCycle) {
      const payload = {
        ...findSelectedCycle,
        scores: findSelectedCycle.scores,
      };
      return payload;
    } else {
      return null;
    }
  };

  /// Popup open and close
  const openUserPopup = Boolean(userAnchorEl);
  const userPopupId = openUserPopup ? "simple-user-popover" : undefined;

  const handleUserPopup = (e) => {
    e.stopPropagation();
    setUserAnchorEl(e.currentTarget);
  };

  const handleCloseUserPopup = (e) => {
    setUserAnchorEl(null);
  };

  const sendClaps = () => {
    //setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "PATCH",
      url: `${API_URL}/experiments/${experiment.id}/`,
      data: { claps: 1 },
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        console.log("YOU CLAPPED", data);
        //setExperiment([...data[0]])
        //setLoading(false)
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure you are logged in.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        //setLoading(false)
      });
  };

  const handleClap = (e) => {
    // sendClaps();
    // setClaps(claps + 1);
  };

  const handleUnarchiveExperiment = (e) => {
    //e.stopPropagation(); // stop the row from being clicked

    props.unArchiveExperiment(experiment, expIndex);
  };

  const handleExperimentStatus = (status) => {
    console.log("experiment-status", status);
    setChangingStatus(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "POST",
      url: `${API_URL}/psr/${experiment.id}/${status}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        console.log("EXPERIMENT-STATUS", data.status);
        setExperiment({ ...experiment, status: data.status });
        //setExperiment([...data[0]])
        setChangingStatus(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure you are logged in.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setChangingStatus(false);
      });
  };

  /// add vertical lines for cycles
  const cycleLines =
    experiment.aggregatecycles &&
    experiment.aggregatecycles.map((aggregateCycle, index) => {
      //  const cycleNo = aggregateCycle.cycleNo;
      //  const epochNo = (cycleNo * experiment.epochs) - (experiment.epochs - aggregateCycle.last_epoch);
      const epochNo = aggregateCycle.last_epoch;
      //  console.log("CYCLE-PRINT", experiment.id, experiment.cycles, experiment.epochs, cycleNo, epochNo, aggregateCycle.last_epoch)

      return (
        <ReferenceLine
          x={epochNo}
          width={2}
          strokeDasharray={flashCycle ? "4 4" : "3 3"}
          strokeOpacity={flashCycle ? 0.6 : 1}
          stroke={flashCycle ? "grey" : "grey"}
          style={{
            filter: flashCycle
              ? "drop-shadow(rgba(53, 118, 167, 1) 0px 0px 2px)"
              : "",
          }}
        />
      );
    });

  // console.log("selectedCycle-selectedEpoch",experiment.id, timeLeft);

  const statusTooltip = () => {
    let title = "";
    if (experiment.status == trainingState.completed) {
      title = "Experiment Completed";
    } else if (experiment.status == trainingState.training) {
      title = "Experiment in Progress​";
    } else if (experiment.status == trainingState.inQueue) {
      title = "Experiment in Queue";
    } else if (experiment.status == trainingState.paused) {
      title = "Experimet Paused​";
    } else if (experiment.status == trainingState.stopped) {
      title = "Experiment Stopped​";
    }
    return title;
  };

  return (
    <Box className="single-row" ref={experimentRef}>
      <Grid container justify="space-between" className="top-buttons">
        <Grid item>
          <MuiToolTip title={statusTooltip()}>
            <Button variant="outlined">
              {experiment.status == trainingState.completed && (
                <CheckIcon className="icon" />
              )}
              {experiment.status == trainingState.training && (
                <TrainingRunningIcon className="icon" />
              )}
              {experiment.status == trainingState.inQueue && (
                <TrainingQueuedIcon className="icon" />
              )}
              {experiment.status == trainingState.paused && (
                <TrainingPausedIcon className="icon" />
              )}
              {experiment.status == trainingState.stopped && (
                <TrainingStoppedIcon className="icon" />
              )}
              <span style={{ marginLeft: "10px", alignSelf: "center" }}>
                {experiment.name}
                &nbsp;
                {/* {experiment.id} */}
                {experiment.experimentKey}
              </span>
            </Button>
          </MuiToolTip>
        </Grid>

        <Grid item>
          <ClapButton
            disabled={true}
            style={{ opacity: 0.6 }}
            handleClap={handleClap}
            claps={claps}
          />
        </Grid>
      </Grid>

      <Box className="inner-view">
        <Grid container justify="flex-start">
          <Grid item className="avatar-container">
            <MuiToolTip
              title={`${experiment?.userId?.first_name} ${experiment?.userId?.last_name}`}
            >
              <Avatar
                onClick={handleUserPopup}
                src={experiment?.userId?.profile_image}
                className="avatar"
                alt={experiment?.userId?.first_name}
              />
            </MuiToolTip>

            <Popover
              id={userPopupId}
              open={openUserPopup}
              anchorEl={userAnchorEl}
              onClose={handleCloseUserPopup}
              elevation={0}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <UserPopup
                itsMe={false}
                profile_image={experiment?.userId?.profile_image}
                user={experiment?.userId}
                closeCallback={handleCloseUserPopup}
              />
            </Popover>
          </Grid>

          <Grid item className="details-container">
            <Grid container justify="flex-start">
              <Grid
                xs={3}
                style={{ maxWidth: "22%", alignSelf: "stretch" }}
                item
              >
                <Box paddingTop={1} pr={5} className="">
                  <Grid container>
                    <Grid xs={6} className="heading-model" item>
                      Model:
                    </Grid>
                    <Grid xs={6} className="heading-model" item>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {experiment.modelName &&
                          experiment.modelName}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={6} className="heading-model" item>
                      Category:
                    </Grid>
                    <Grid xs={6} className="heading-model" item>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {experiment.category}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid xs={6} className="heading-model" item>
                      Date:
                    </Grid>
                    <Grid xs={6} className="heading-model" item>
                      <span>
                        {formatDate(new Date(experiment.created_date))}
                      </span>
                    </Grid>
                  </Grid>
                </Box>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box mt={3} paddingTop={1} pr={5}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span className="heading-model">
                        Objective / Hypothesis
                      </span>
                      {experiment?.userId?.id === user.user_id && (
                        <EditObjectiveIcon className="icon" />
                      )}
                    </Grid>

                    {objectiveDisabled ? (
                      <div
                        style={{
                          wordBreak: "break-all",
                          minHeight: "80px",
                          fontStyle: "normal",
                          fontWeight: "normal",
                        }}
                        onClick={onObjectiveClick}
                      >
                        <ReactMarkdown>{objective}</ReactMarkdown>
                      </div>
                    ) : (
                      <TextField
                        //onClick={onObjectiveClick}
                        inputRef={objectiveRef}
                        //onBlur={onObjectiveBlur}
                        fullWidth
                        focused
                        disabled={objectiveDisabled}
                        className="objective-box normal-text my-8"
                        id="standard-multiline-static"
                        label=""
                        multiline
                        minRows={8}
                        InputProps={{ disableUnderline: true }}
                        defaultValue={objective}
                      />
                    )}
                  </Box>
                </ClickAwayListener>
              </Grid>
              <Grid
                xs={9}
                style={{ maxWidth: "78%", flexBasis: "78%" }}
                item
                container
                direction="column"
              >
                <Grid container justify="flex-start">
                  <Grid
                    xs={6}
                    item
                    className={`graph-box ${
                      fullscreenAccuracy ? "fullscreen-graph" : ""
                    }`}
                  >
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      className="graph-header"
                    >
                      <MuiToolTip
                        title={
                          <React.Fragment>
                            <b>Accuracy</b>
                            {
                              " is the proportion of correct predictions (both true positives and true negatives) among the total number of cases examine​"
                            }
                          </React.Fragment>
                        }
                      >
                        <span className="heading">Accuracy</span>
                      </MuiToolTip>
                      <MuiToolTip title="Accuracy achieved with Training Data​">
                        <span className="values training">
                          Training:{" "}
                          <span>
                            {(+selectedEpoch?.trainingAccuracy || 0.0).toFixed(
                              2
                            )}
                          </span>
                        </span>
                      </MuiToolTip>

                      <MuiToolTip title="Accuracy achieved with Validation Data​​">
                        <span className="values validation mx-8">
                          Validation:{" "}
                          <span>
                            {(
                              +selectedEpoch?.validationAccuracy || 0.0
                            ).toFixed(2)}
                          </span>
                        </span>
                      </MuiToolTip>

                      <MuiToolTip title="Expand Graph">
                        <Button
                          variant="outlined"
                          onClick={handleFullscreenAccuracy}
                        >
                          <FullScreenIcon
                            style={{ transform: "rotate(-45deg)" }}
                            className="icon"
                          />
                        </Button>
                      </MuiToolTip>
                    </Grid>

                    <ResponsiveContainer
                      width={"100%"}
                      height={fullscreenAccuracy ? "100%" : 185}
                    >
                      <ComposedChart
                        onClick={chartClickHandler}
                        syncId={`experimentChart-${experiment.id}`}
                        data={experiment.aggregate_epochs}
                        // margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        margin={{ left: -20, top: 20, right: 20 }}
                      >
                        {/* <ReferenceLine ifOverflow="extendDomain" x={300} stroke="#e6e6e6" label="" /> */}
                        <XAxis
                          interval={0}
                          tick={renderEpochTick}
                          type="category"
                          tickLine={false}
                          axisLine={false}
                          height={40}
                          dataKey="index"
                        >
                          {/* <Label
                                                        height={20}
                                                        value="Epochs"
                                                        offset={2}
                                                        position="insideBottomRight"
                                                        style={{ fontSize: '80%', fill: 'rgba(0, 0, 0, 0.7)' }}

                                                    /> */}
                        </XAxis>
                        {/* <XAxis
                                                    interval="preserveEnd"
                                                    dataKey="epochNo"
                                                    axisLine={false}
                                                    type="category"
                                                    tickLine={false}
                                                    // height={2}
                                                    // tick={renderCycleTick}
                                                    tick={(tickProps) => {
                                                        return renderCycleTick(tickProps, experiment)
                                                    }}
                                                    xAxisId="quarter"
                                                    /> */}

                        <YAxis
                          tick={{ fontSize: 10 }}
                          tickFormatter={(value) => (+value).toFixed(2)}
                          type="number"
                          tickLine={false}
                          axisLine={false}
                        />
                        <CartesianGrid vertical={false} stroke="#e6e6e6" />
                        <Tooltip content={renderToolTip} />
                        <Line
                          //dot={{ strokeWidth: 0, r: 0 }}
                          dot={false}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          dataKey="trainingAccuracy"
                          stroke="#ffae15"
                        />
                        <Line
                          //dot={{ strokeWidth: 2, r: 4 }}
                          dot={false}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          // connectNulls={true}
                          dataKey="validationAccuracy"
                          stroke="#3576a7"
                        />
                        {cycleLines}
                      </ComposedChart>
                    </ResponsiveContainer>

                    {experiment.aggregate_epochs.length === 0 && (
                      <EmptyGraph status={experiment.status} type="accuracy" />
                    )}
                    {experiment.aggregate_epochs.length > 0 && (
                      <MuiToolTip title="Epoch​ is one cycle through the full training dataset​​">
                        <div className="epoch">Epoch</div>
                      </MuiToolTip>
                    )}
                  </Grid>
                  <Grid
                    xs={6}
                    item
                    className={`graph-box ${
                      fullscreenLoss ? "fullscreen-graph" : ""
                    }`}
                  >
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      className="graph-header"
                    >
                      <MuiToolTip title="Loss Function​">
                        <span className="heading">
                          Loss:{" "}
                          {
                            experiment?.trainingPlan?.hyperparameters
                              ?.lossFunction
                          }
                        </span>
                      </MuiToolTip>
                      <MuiToolTip title="Accuracy achieved with Training Data​​">
                        <span className="values training">
                          T:{" "}
                          <span>
                            {(+selectedEpoch?.trainingLoss || 0.0).toFixed(2)}
                          </span>
                        </span>
                      </MuiToolTip>
                      <MuiToolTip title="Accuracy achieved with Validation Data​">
                        <span className="values validation mx-8">
                          V:{" "}
                          <span>
                            {(+selectedEpoch?.validationLoss || 0.0).toFixed(2)}
                          </span>
                        </span>
                      </MuiToolTip>

                      <MuiToolTip title="Expand Graph​">
                        <Button
                          variant="outlined"
                          onClick={handleFullscreenLoss}
                        >
                          <FullScreenIcon
                            style={{ transform: "rotate(-45deg)" }}
                            className="icon"
                          />
                        </Button>
                      </MuiToolTip>
                    </Grid>
                    <ResponsiveContainer
                      width={"100%"}
                      height={fullscreenLoss ? "100%" : 185}
                    >
                      <ComposedChart
                        onClick={chartClickHandler}
                        syncId={`experimentChart-${experiment.id}`}
                        data={experiment.aggregate_epochs}
                        margin={{ left: -20, top: 20, right: 20 }}
                      >
                        {/* <ReferenceLine ifOverflow="extendDomain" y={1} alwaysShow={true} stroke="#e6e6e6" label="actual" /> */}

                        <XAxis
                          interval={0}
                          tick={renderEpochTick}
                          type="category"
                          tickLine={false}
                          axisLine={false}
                          height={40}
                          dataKey="index"
                        >
                          {/* <Label
                                                            content={<text>Epochs</text>}
                                                            offset={2}
                                                            position="insideBottomRight"
                                                            style={{ fontSize: '80%', fill: 'rgba(0, 0, 0, 0.7)' }}
                                                        /> */}
                        </XAxis>
                        {/* <XAxis
                                                    interval="preserveEnd"
                                                    dataKey="epochNo"
                                                    axisLine={false}
                                                    type="category"
                                                    tickLine={false}
                                                    // height={2}
                                                    // tick={renderCycleTick}
                                                    tick={(tickProps) => {
                                                        return renderCycleTick(tickProps, experiment)
                                                    }}
                                                    xAxisId="quarter"
                                                    /> */}
                        <YAxis
                          tick={{ fontSize: 10 }}
                          tickFormatter={(value) => (+value).toFixed(2)}
                          type="number"
                          tickLine={false}
                          axisLine={false}
                        />
                        <CartesianGrid vertical={false} stroke="#e6e6e6" />
                        <Tooltip content={renderToolTip} />
                        <Line
                          // dot={{ strokeWidth: 2, r: 4 }}
                          dot={false}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          dataKey="trainingLoss"
                          stroke="#ffae15"
                        />
                        <Line
                          // dot={{ strokeWidth: 2, r: 4 }}
                          dot={false}
                          activeDot={{ strokeWidth: 0, r: 3 }}
                          type="linear"
                          dataKey="validationLoss"
                          stroke="#3576a7"
                        />
                        {cycleLines}
                      </ComposedChart>
                    </ResponsiveContainer>

                    {experiment.aggregate_epochs.length === 0 && (
                      <EmptyGraph status={experiment.status} type="loss" />
                    )}
                    {experiment.aggregate_epochs.length > 0 && (
                      <MuiToolTip title="Epoch​ is one iteration through the full training dataset​">
                        <div className="epoch">Epoch</div>
                      </MuiToolTip>
                    )}
                  </Grid>
                </Grid>

                <Grid container className="detailed-list">
                  {selectedCycle.scores &&
                    Object.keys(selectedCycle.scores).map((key, index) => {
                      if (key === "f1_score") {
                        return (
                          <Grid xs={3} item className="item">
                            <MuiToolTip title="F1 score​ is the harmonic mean of precision and recall​">
                              <span className="head">{key}</span>
                            </MuiToolTip>
                            <MuiToolTip title="F1 score​ is the harmonic mean of precision and recall​">
                              <span className="normal-text">
                                {(+selectedCycle.scores[key]).toFixed(2)}
                              </span>
                            </MuiToolTip>
                          </Grid>
                        );
                      } else if (key === "precision") {
                        return (
                          <Grid xs={3} item className="item">
                            <MuiToolTip title="precision ​is the number of true positive results divided by the number of all positive results, including those not identified correctly​">
                              <span className="head">{key}</span>
                            </MuiToolTip>
                            <MuiToolTip title="precision ​is the number of true positive results divided by the number of all positive results, including those not identified correctly​">
                              <span className="normal-text">
                                {(+selectedCycle.scores[key]).toFixed(2)}
                              </span>
                            </MuiToolTip>
                          </Grid>
                        );
                      } else if (key === "recall") {
                        return (
                          <Grid xs={3} item className="item">
                            <MuiToolTip title="recall ​is the number of true positive results divided by the number of all samples that should have been identified as positive​">
                              <span className="head">{key}</span>
                            </MuiToolTip>
                            <MuiToolTip title="recall ​is the number of true positive results divided by the number of all samples that should have been identified as positive​">
                              <span className="normal-text">
                                {(+selectedCycle.scores[key]).toFixed(2)}
                              </span>
                            </MuiToolTip>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid xs={3} item className="item">
                            <MuiToolTip title={key}>
                              <span className="head">{key}</span>
                            </MuiToolTip>
                            <MuiToolTip title={key}>
                              <span className="normal-text">
                                {(+selectedCycle.scores[key]).toFixed(2)}
                              </span>
                            </MuiToolTip>
                          </Grid>
                        );
                      }
                    })}
                </Grid>

                <Grid item className="control-box">
                  <Grid
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    container
                  >
                    <Grid xs={3} className="color-container" item>
                      <div className="normal-text">
                        Epoch &nbsp;
                        <span className="cycle-no">
                          {selectedEpoch?.epochIndex - 1 || 0}
                        </span>
                        &nbsp; of
                        <span className="rounds total-cycles">
                          {experiment.cycles * experiment.epochs}
                        </span>
                      </div>
                    </Grid>
                    <MuiToolTip
                      title={
                        experiment.status === trainingState.completed ||
                        experiment.status === trainingState.stopped
                          ? "Training Time taken for Completion​"
                          : "Training Time Left​"
                      }
                    >
                      <Grid
                        xs={3}
                        item
                        className="color-container"
                        alignSelf="center"
                      >
                        {experiment.status === trainingState.completed ||
                        experiment.status === trainingState.stopped ? (
                          <ClockIcon
                            className="icon"
                            style={{ width: "24px", height: "24px" }}
                          />
                        ) : (
                          <ClockCountIcon
                            className="icon"
                            style={{ width: "24px", height: "24px" }}
                          />
                        )}
                        <span className="est-time">
                          {timeLeft !== 0 ? convertSecondsToDay(timeLeft) : "~"}
                        </span>
                      </Grid>
                    </MuiToolTip>

                    <Grid xs={3} className="color-container" item>
                      <div className="buttons-list">
                        <MuiToolTip title="Start Experiment​">
                          <span>
                            <Button
                              className="first-child"
                              aria-label="delete"
                              // onClick={() => handleExperimentStatus(trainingState.inQueue)}
                              // disabled={!(experiment.status === trainingState.paused) || !(experiment?.userId?.id === user.user_id)}
                              disabled={true}
                            >
                              {changingStatus ? (
                                <CircularProgress size={10} />
                              ) : (
                                <PlayArrowRoundedIcon
                                  style={{
                                    filter: !(
                                      experiment.status === trainingState.paused
                                    )
                                      ? "grayscale(2) brightness(1.5)"
                                      : "",
                                  }}
                                  className="icon"
                                />
                              )}
                            </Button>
                          </span>
                        </MuiToolTip>

                        <MuiToolTip title="Pause Experiment​">
                          <span>
                            <Button
                              title="Pause Experiment​"
                              // onClick={() => handleExperimentStatus(trainingState.paused)}
                              // disabled={!(experiment.status === trainingState.training || experiment.status === trainingState.inQueue) || !(experiment?.userId?.id === user.user_id)}
                              disabled={true}
                            >
                              {changingStatus ? (
                                <CircularProgress size={10} />
                              ) : (
                                <PauseRoundedIcon
                                  style={{
                                    filter: !(
                                      experiment.status ===
                                        trainingState.training ||
                                      experiment.status ===
                                        trainingState.inQueue
                                    )
                                      ? "grayscale(2) brightness(1.5)"
                                      : "",
                                  }}
                                  className="icon"
                                />
                              )}
                            </Button>
                          </span>
                        </MuiToolTip>

                        <MuiToolTip title="Stop Experiment​">
                          <span>
                            <Button
                              title="Stop Experiment​"
                              className="last-child"
                              // onClick={() => handleExperimentStatus(trainingState.stopped)}
                              // disabled={
                              //     !(experiment.status === trainingState.training|| experiment.status === trainingState.inQueue)
                              //     || !(experiment?.userId?.id === user.user_id)
                              // }
                              disabled={true}
                            >
                              {changingStatus ? (
                                <CircularProgress size={10} />
                              ) : (
                                <StopRoundedIcon
                                  style={{
                                    filter: !(
                                      experiment.status ===
                                        trainingState.training ||
                                      experiment.status ===
                                        trainingState.inQueue
                                    )
                                      ? "grayscale(2) brightness(1.5)"
                                      : "",
                                  }}
                                  className="icon"
                                />
                              )}
                            </Button>
                          </span>
                        </MuiToolTip>
                      </div>
                    </Grid>

                    <Grid xs={4} className="color-container" item>
                      <span className="normal-text">Utilization: </span>
                      <span
                        style={{
                          width: "60%",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                        }}
                        className="est-time"
                      >
                        <MuiToolTip title="Flops utilized​">
                          <span>
                            {nFormatter(experiment.flopsutilised, 2)}F
                          </span>
                        </MuiToolTip>
                        {experiment.status !== trainingState.completed && (
                          <>
                            &nbsp;of&nbsp;
                            <MuiToolTip title="Estimated Flops needed to complete Experiment​​">
                              <span>
                                {nFormatter(experiment.estimatedflops, 2) !==
                                "~ "
                                  ? "~ "
                                  : ""}
                                {nFormatter(experiment.estimatedflops, 2)}F
                              </span>
                            </MuiToolTip>
                          </>
                        )}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justify="space-between" className="bottom-buttons">
          <div className="buttons-list">
            <MuiToolTip title="Confusion Matrix">
              <Button
                className={showConfusionMatrix ? "selected" : ""}
                onClick={useCallback(() => {
                  setShowConfusionMatrix(!showConfusionMatrix);
                  setShowTrainingPlan(false);
                  setShowCodeView(false);
                  setShowComments(false);
                })}
              >
                <ConfusionMatrixIcon className="icon" />
                {showConfusionMatrix && (
                  <span className="title">Confusion Matrix</span>
                )}
              </Button>
            </MuiToolTip>
            <MuiToolTip title="Training Plan">
              <Button
                className={showTrainingPlan ? "selected" : ""}
                onClick={useCallback(() => {
                  setShowTrainingPlan(!showTrainingPlan);
                  setShowConfusionMatrix(false);
                  setShowCodeView(false);
                  setShowComments(false);
                  getCycleInfo();
                })}
              >
                <TrainingPlanIcon className="icon" />
                {showTrainingPlan && (
                  <span className="title">Training Plan</span>
                )}
              </Button>
            </MuiToolTip>
            <MuiToolTip title="Discussion">
              <Button
                className={showComments ? "selected" : ""}
                // onClick={useCallback(() => {
                //     setShowComments(!showComments);
                //     setShowConfusionMatrix(false);
                //     setShowCodeView(false);
                //     setShowTrainingPlan(false);
                // })}
                disabled={true}
              >
                <CommentsIcon className="icon" />
                {showComments && <span className="title">Discussion</span>}
              </Button>
            </MuiToolTip>
            <MuiToolTip title="Model Code">
              <Button
                className={showCodeView ? "selected" : ""}
                onClick={useCallback(() => {
                  setShowCodeView(!showCodeView);
                  setShowConfusionMatrix(false);
                  setShowComments(false);
                  setShowTrainingPlan(false);
                })}
              >
                <CodeViewIcon
                  style={{
                    width: "16px",
                    height: "auto",
                    filter: showCodeView
                      ? "saturate(0%) brightness(1000%) contrast(100%)"
                      : "",
                  }}
                  className="icon"
                />
                {showCodeView && <span className="title">Code</span>}
              </Button>
            </MuiToolTip>
          </div>

          <div className="buttons-list">
            <MuiToolTip
              title={
                selectedCycle.cycleNo
                  ? "Download Model, Weights, Training Plan​"
                  : "Please choose a cycle in the graph."
              }
            >
              <Button
                onClick={
                  !selectedCycle.cycleNo
                    ? downloadWeightsHandleDisabled
                    : downloadWeightsHandle
                }
              >
                {!downloading ? (
                  <DownloadModelIcon
                    className="icon"
                    style={{
                      filter: !selectedCycle.cycleNo
                        ? "grayscale(2) brightness(1.5)"
                        : "",
                    }}
                  />
                ) : (
                  <CircularProgress size={10} />
                )}
              </Button>
            </MuiToolTip>

            <MuiToolTip title="Unarchive Experiment​">
              <Button
                onClick={handleUnarchiveExperiment}
                className="icon-button"
                color="primary"
                variant="outlined"
              >
                <UnarchiveIcon className="icon" />
              </Button>
            </MuiToolTip>
          </div>
        </Grid>
      </Box>

      {(showComments ||
        showCodeView ||
        showTrainingPlan ||
        showConfusionMatrix) && (
        <Box className="bottom-sheets">
          <Comments
            Me={user}
            experiment={experiment.id}
            expanded={showComments}
          />
          <CodeView experiment={experiment.id} expanded={showCodeView} />
          <ConfusionMatrix
            cycleNo={selectedCycle.cycleNo}
            experiment={experiment.id}
            confusionMatrix={selectedCycle.confusion_matrix}
            expanded={showConfusionMatrix}
          />
          <TrainingPlan
            experiment={{
              augmentation_parameters: experiment.augmentation_parameters,
              hyperparameters: experiment.hyperparameters,
              training_parameters: experiment.training_parameters,
            }}
            expanded={showTrainingPlan}
          />
        </Box>
      )}

      <Portal>
        <Snackbar
          open={downloadStarted}
          autoHideDuration={6000}
          onClose={() => setDownloadStarted(false)}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            variant="filled"
            severity="success"
            iconMapping={{
              success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
            }}
            onClose={() => setDownloadStarted(false)}
          >
            {`Model is downloading at epoch ${selectedEpoch?.epochIndex - 1}`}
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
};

export default Row;

import {
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Portal,
  Snackbar,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import avatar from "assets/pexelsPixabay220453318c14b7.png";

import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { ColabIcon, NotificationCheckIcon, NotificationIcon } from "Components/Icon";
import { GlobalContext } from "Context/GlobalState";
import { nFormatter, numberWithCommas } from "utils/format";
import { Alert } from "@material-ui/lab";
import Notifications from "Components/Notifications";

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#FFFFFF",
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #FFFFFF",
    borderRadius: "2px",
  },
}))(Tooltip);

const HtmlNotiTooltip = withStyles((theme) => ({
  arrow: {
    color: "#FFFFFF",
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 273,
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #FFFFFF",
    borderRadius: "2px",
  },
}))(Tooltip);

function Header(props) {
  const { user } = useContext(GlobalContext);
  const [outOfFlops, setOutOfFlops] = useState(false);
  // const { total_available_flops } = user;
  const [openNotifications, setOpenNotifications] = useState(false);

  // const handleTooltipClose = () => {
  //   setOpenNotifications(false);
  // };

  const handleNotifications = (status) => {
    setOpenNotifications(status);

  };

  const handleColabClick = () => {
    const url =
      "https://colab.research.google.com/drive/1N00idtpoaq1lk9OJE6g4bMqd8o-Qex2C?usp=sharing";
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (user?.total_available_flops < 10) {
      showOutOfFlopsMessage();
    }
  }, [user?.total_available_flops]);

  const showOutOfFlopsMessage = (e) => {
    setOutOfFlops(true);
    setTimeout(() => {
      setOutOfFlops(false);
    }, 5000);
  };

  

  return (
    <>
      
      <Grid className="global-header" container direction="row">
        <Grid item style={{ marginLeft: "auto", marginRight: "24px" }}>
          {user?.user_available_flops > 0 && (
            <HtmlTooltip
              arrow
              // open={true}
              // disableFocusListener
              // disableHoverListener
              // disableTouchListener
              title={
                <div className="flops-tooltip">
                  <div className="tooltip-flops-title">Your available Flops</div>
                  <div className="tooltip-flops-value">
                    {numberWithCommas(user?.user_available_flops || "")}
                  </div>
                </div>
              }
            >
              <div className="tflops-text">
                <span>User</span>
                {nFormatter(user?.user_available_flops, 2) == "0"
                  ? "0 "
                  : nFormatter(user?.user_available_flops, 2)}
                F
              </div>
            </HtmlTooltip>
          )}
          
          {/* <span className='bold-400-12'>User</span> */}
        </Grid>

        {user?.company_available_flops > 0 && (
          <Grid item style={{ marginLeft: "8px", marginRight: "8px" }}>
            <HtmlTooltip
              arrow
              // open={true}
              // disableFocusListener
              // disableHoverListener
              // disableTouchListener
              title={
                <div className="flops-tooltip">
                  <div className="tooltip-flops-title">
                    Your company available Flops
                  </div>
                  <div className="tooltip-flops-value">
                    {numberWithCommas(user?.company_available_flops || "0")}
                  </div>
                </div>
              }
            >
              <div className="tflops-text">
                <span>Company</span>
                {nFormatter(user?.company_available_flops, 2) == "0"
                  ? "0 "
                  : nFormatter(user?.company_available_flops, 2)}
                F
              </div>
            </HtmlTooltip>

            {/* <span className='bold-400-12'>Company</span> */}
          </Grid>
        )}

        <ClickAwayListener onClickAway={() => handleNotifications(false)}>
          <Grid item>

              <Notifications
                open={openNotifications}
                handleNotifications={handleNotifications}
              />
            
            </Grid>
        </ClickAwayListener>


        <Grid item>
          <Tooltip title={`Start training with Google Colab​`}>
            <Button
              className="colab-button"
              endIcon={<ColabIcon style={{ width: "20px" }} />}
              onClick={handleColabClick}
              variant="outlined"
            >
              Train your model
            </Button>
          </Tooltip>
        </Grid>
        </Grid>
        
        
      <Portal>
        {outOfFlops && (
          <Snackbar
            open={outOfFlops}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="warning"
            >
              You have consumed your alocated flops.
            </Alert>
          </Snackbar>
        )}
      </Portal>
    </>
  );
}

export default Header;

import React, { createContext, useReducer } from "react";
import {
  setDatasetOptions,
  setDataset,
  setCreatingDataset,
  setErrorMessage,
  setSideBar,
  setUserLogin,
  setDatasetImages,
  saveDatasetInDB,
  loadingDatasets,
  loadDatasets,
  loadExperiments,
  loadingExperiments,
  setMedicalDataset,
  loadingCompetitions,
  loadCompetitions,
  archiveDataset,
  setUpdateExperimentsStatus,
  loadArchivedExperiments,
  loadingArchivedExperiments,
  unArchiveDataset,
  setAutonomousDataset,
  setJoinCompetitions,
} from "./Actions";
import AppReducer from "./AppReducer";
import axios from "axios";
import { groupDefects } from "../utils/DefectGrouping";
import { Defects, GenerateRESTUrls } from "../utils";

let SERVER = "https://xray-json-server.azurewebsites.net/";
let OLD_API = "https://xray-json-server.azurewebsites.net/datasets";
let API = "https://tracebloc.azurewebsites.net";
export const SAMPLES_IMAGES_URL =
  "https://xrayimagedata.blob.core.windows.net/xrayimages/";

console.log("MY_ENVIRONMENT", process?.env?.REACT_APP_MY_ENVIRONMENT);
if (
  process?.env?.REACT_APP_MY_ENVIRONMENT === "development" ||
  process?.env?.NODE_ENV === "development"
) {
  SERVER = "https://xray-json-server.azurewebsites.net/";
  OLD_API = "https://xray-json-server.azurewebsites.net/datasets";
  API = "https://xray-backend-develop.azurewebsites.net";
}

if (process?.env?.REACT_APP_MY_ENVIRONMENT === "staging") {
  SERVER = "https://xray-json-server.azurewebsites.net/";
  OLD_API = "https://xray-json-server.azurewebsites.net/datasets";
  API = "https://xray-backend-staging.azurewebsites.net";
}


// this for local development environment
// if (process?.env?.NODE_ENV === "development") {
//     OLD_API    = 'http://localhost:3000/datasets'
//     SERVER = 'http://localhost:3000/'
//     API = 'http://127.0.0.1:8000'

//     // SERVER = 'https://xray-json-server.azurewebsites.net/'
//     // OLD_API = 'https://xray-json-server.azurewebsites.net/datasets'
//     // API = 'https://xray-backend-develop.azurewebsites.net'
// }

// process?.env?.REACT_APP_MY_ENVIRONMENT

axios.defaults.baseURL = API;

export const OLD_API_URL = OLD_API;
export const OLD_SERVER_URL = SERVER;

export const API_URL = API;
export const SERVER_URL = SERVER;

const initialState = {
  sidebar: {
    open: false,
  },
  datasetOptions: {},
  dataset: {
    loading: false,
    list: [],
    count: null,
    groups: [],
    total: 2031 + (Math.floor(Math.random() * 60) + 9), // this should ideally come from backend
    ok: 0,
    nok: 0,
    amplify: 1,
    saved: null,
  },
  datasets: { loading: false, count: 0, list: [], prev: null, next: null }, // list of logged in user datasets
  experiments: { loading: false, count: 0, list: [], prev: null, next: null }, // list of logged in user datasets
  archivedExperiments: {
    loading: false,
    count: 0,
    list: [],
    prev: null,
    next: null,
  }, // list of logged in user archived datasets
  competitions: { loading: false, count: 0, list: [], prev: null, next: null }, // list of logged in user datasets
  user: null, // {name: 'tracebloc'},
  error: null, // error message return by a failed network response
  network: false, // If True, the network request is in progress
};

export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const openSideBar = (value) => {
    dispatch(setSideBar(value));
  };

  function login({ username, password, consent }) {
    axios
      .post(`${API_URL}/api-token-auth/`, { username, password, consent })
      .then((res) => {
        const user = { ...res.data };
        localStorage.setItem("_key_usr_tkn", user.token);
        delete user.token;
        console.log(user);
        dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          if (error.response.status === 401 && error.response.data) {
            dispatch(setErrorMessage({ error: error.response.data.message }));
          }

          if (error.response.status === 400) {
            dispatch(
              setErrorMessage({
                error: "Please make sure your credentials are correct.",
              })
            );
          }
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
      });
    //dispatch(setUserLogin({ id: 1, name: 'Dr.Berhnard', location: 'Berlin', designation: 'Data Scientist' }));
  }

  const logout = (title, imageSamples) => {
    //http://127.0.0.1:8000/dataset/
    const token = localStorage.getItem("_key_usr_tkn");
    localStorage.setItem("_key_usr_tkn", "");
    const config = {
      method: "post",
      url: `${API_URL}/logout/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        //dispatch(saveDatasetInDB(dataset))
        // dispatch(setUserLogin(user));
        window.location.reload(false);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
      });
  };

  const setUserUpdate = (user) => dispatch(setUserLogin(user));

  function getUserInfo() {
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/userinfo/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const user = { ...res.data };
        dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          if (error.response.status === 401 && error.response.data) {
            dispatch(setErrorMessage({ error: error.response.data.detail }));
            console.log(error.response.data.detail)
            if (error.response.data.detail === "Invalid token.") {
              localStorage.removeItem("_key_usr_tkn");
              window.location = "/"
            }
          } else {
            dispatch(
              setErrorMessage({
                error: "Please make sure your credentials are correct.",
              })
            );
          }
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
      });
    //dispatch(setUserLogin({ id: 1, name: 'Dr.Berhnard', location: 'Berlin', designation: 'Data Scientist' }));
  }
  /// this method is used for storing all the dataset configurations
  const createDatasetOptions = (oneOption) => {
    dispatch(setDatasetOptions(oneOption));
  };

  const createDataSet = ({
    ratio,
    defects,
    voltages,
    parts,
    material,
    company,
  }) => {
    dispatch(setCreatingDataset(true));

    voltages = voltages.filter((item) => item.label !== "all");
    parts = parts.filter((item) => item.label !== "all");
    material = material.filter((item) => item.label !== "all");
    let defectsString =
      defects &&
      defects.map((item) => `${item.label}=${item.diameter}`).join("&");
    //defectsString = defectsString.join('&')
    console.log("defectsString-DATA", defectsString, defects);
    let voltageString =
      voltages &&
      voltages.length > 0 &&
      `voltage=${voltages
        .map((item) => `${item.label.slice(0, -2)}`)
        .join(",")}`;
    console.log("voltages-DATA", voltageString, voltages);
    let partsString =
      parts &&
      parts.length > 0 &&
      `part=${parts.map((item) => `${item.label}`).join(",")}`;
    console.log("parts-DATA", partsString, parts);
    let materialString =
      material &&
      material.length > 0 &&
      `material=${material.map((item) => `${item.label}`).join(",")}`;
    console.log("parts-DATA", materialString, material);
    let companyString =
      company &&
      company.length > 0 &&
      `company=${company.map((item) => `${item.label}`).join(",")}`;
    console.log("parts-DATA", companyString, company);

    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/createdataset/?ratio=${ratio}&${defectsString}&${voltageString}&${partsString}&${materialString}&${companyString}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const dataset = { ...res.data };
        console.log("DATASET-RETURNED", dataset);
        dispatch(
          setDataset({
            count: dataset.count,
            dataset_type: dataset.type,
            groups: dataset.grouped_defect_data,
            ok: dataset.ok,
            nok: dataset.nok,
          })
        );
        // dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(setCreatingDataset(false));
      });
  };

  const createMedicalDataSet = ({
    ratio,
    classes,
    company,
    type,
    category,
  }) => {
    dispatch(setCreatingDataset(true));

    const classesString =
      classes && `classes=${classes.map((item) => `${item.label}`).join(",")}`;
    console.log("defectsString-DATA", classesString, classes);
    const companyString =
      company &&
      company.length > 0 &&
      `company=${company.map((item) => `${item.label}`).join(",")}`;
    console.log("parts-DATA", companyString, company);
    const typeString = `type=${type}`;

    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/create-medical-xray-dataset/?ratio=${ratio}&${classesString}&${companyString}&${typeString}&category=${category}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const dataset = { ...res.data };
        console.log("DATASET-RETURNED", dataset);
        dispatch(
          setMedicalDataset({
            description: dataset.description,
            label_density: dataset.label_density,
            data_items_label_count: dataset.data_items_label_count,
            unique_data_items_count: dataset.unique_data_items_count,
            count: dataset.count,
            data_samples: dataset.sample_images,
            dataset_type: dataset.type,
            groups: dataset.groupedDefectData,
            ok: dataset.ok,
            nok: dataset.nok,
          })
        );
        // dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(setCreatingDataset(false));
      });
  };

  const createAutonomousDataSet = ({
    ratio,
    classes,
    company,
    type,
    category,
  }) => {
    dispatch(setCreatingDataset(true));

    const classesString =
      classes && `classes=${classes.map((item) => `${item.label}`).join(",")}`;
    console.log("defectsString-DATA", classesString, classes);
    const companyString =
      company &&
      company.length > 0 &&
      `company=${company.map((item) => `${item.label}`).join(",")}`;
    console.log("parts-DATA", companyString, company);
    const typeString = `type=${type}`;

    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/create-autonomous-dataset/?ratio=${ratio}&${classesString}&${companyString}&${typeString}&category=${category}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const dataset = { ...res.data };
        console.log("DATASET-RETURNED", dataset);
        dispatch(
          setAutonomousDataset({
            description: dataset.description,
            label_density: dataset.label_density,
            data_items_label_count: dataset.data_items_label_count,
            unique_data_items_count: dataset.unique_data_items_count,
            count: dataset.count,
            data_samples: dataset.sample_images,
            dataset_type: dataset.type,
            groups: dataset.groupedDefectData,
            ok: dataset.ok,
            nok: dataset.nok,
          })
        );
        // dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(setCreatingDataset(false));
      });
  };

  // getDataSet is  used to get samples images from the Mock server
  const getDataSet = ({
    ok,
    nok,
    defects,
    voltages,
    parts,
    material,
    company,
  }) => {
    let urls = [];
    const requests = [];

    dispatch(setCreatingDataset(true));

    // console.log('defects', defects);
    // console.log('Defects', Defects);

    // return

    urls = GenerateRESTUrls("NOK", {
      ok,
      nok,
      defects,
      voltages,
      parts,
      material,
      company,
    });
    // urls = [...newUrls];
    urls = [
      ...GenerateRESTUrls("OK", {
        ok,
        nok,
        defects,
        voltages,
        parts,
        material,
        company,
      }),
      ...urls,
    ];

    //urls.unshift(`${API_URL}?defect=OK&_limit=${ok}`);

    console.log("URLS", urls);

    urls.forEach((url) => {
      requests.push(axios.get(url));
    });

    axios
      .all(requests)
      .then((allRes) => {
        // Both requests are now complete
        //console.log("RES", allRes);
        let data = [];
        allRes.forEach((res) => {
          data = [...data, ...res.data];
        });

        let uniqueData = Array.from(new Set(data.map((a) => a.id))).map(
          (id) => {
            return data.find((a) => a.id === id);
          }
        );
        //console.log("data", data.length, uniqueData.length);
        //uniqueData = uniqueData.slice(0, ok + nok)

        console.log("uniqueData", uniqueData);

        let actualOK = 0;
        let actualNOK = 0;
        uniqueData.forEach((d) => {
          if (d.defect === "OK") {
            actualOK++;
          } else {
            actualNOK++;
          }
        });
        console.log(
          "GROUPS--NOK",
          "total",
          initialState.dataset.total,
          "ok",
          ok,
          "nok",
          nok
        );
        console.log(
          "GROUPS-ACTUAL-NOK",
          "TOTAL",
          uniqueData.length,
          "actualOK",
          actualOK,
          "actualNOK",
          actualNOK
        );
        const groups = groupDefects(defects, uniqueData);
        //console.log("porosity", groups);

        dispatch(setDatasetImages({ dataset: uniqueData, groups, ok, nok }));
      })
      .catch((err) => {
        console.log("getDataSet Error", err);
        dispatch(setCreatingDataset(false));
      });
  };

  const saveDataset = (title, imageSamples, dataset_type, access_type) => {
    //http://127.0.0.1:8000/dataset/
    const token = localStorage.getItem("_key_usr_tkn");
    let data = { title, access_type };
    if (dataset_type === "industrial") {
      data.data_samples = imageSamples;
    }
    const config = {
      method: "post",
      url: `${API_URL}/dataset/`,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const dataset = { ...res.data };
        console.log("DATASET-SAVED", dataset);
        dispatch(saveDatasetInDB(dataset));
        // dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
      });
  };

  const getUserDatasets = (page) => {
    dispatch(loadingDatasets(true));
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/dataset/${page ? "?page=" + page : ""}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const datasets = { ...res.data };
        console.log("DATASETS-LIST", datasets);
        dispatch(loadDatasets(datasets));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingDatasets(false));
      });
  };

  const getUserExperiments = (page) => {
    dispatch(loadingExperiments(true));
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/experiments/${page ? "?page=" + page : ""}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const datasets = res.data;
        console.log("DATASETS-LIST", datasets);
        dispatch(loadExperiments(datasets));
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingExperiments(false));
      });
  };

  const getUserArchivedExperiments = (page) => {
    dispatch(loadingArchivedExperiments(true));
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "get",
      url: `${API_URL}/experiments/?show-archived=1${
        page ? "&page=" + page : ""
      }`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const datasets = res.data;
        console.log("DATASETS-LIST", datasets);
        dispatch(loadArchivedExperiments(datasets));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingArchivedExperiments(false));
      });
  };

  const updateExperimentsStatus = (datasets) => {
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "GET",
      url: `${API_URL}/experiments-status/?datasets=${datasets}`,
      data: { claps: 1 },
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config)
      .then((res) => {
        const data = res.data;
        dispatch(setUpdateExperimentsStatus(data.datasets));
        dispatch(setUserLogin({ ...data.flops_info }));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
      });
  };

  const emptyUserExperiments = () => {
    loadExperiments({ count: 0, list: [], prev: null, next: null });
  };

  const updateArchiveDataset = (dataset) => {
    dispatch(archiveDataset(dataset));
  };

  const updateUnarchiveDataset = (dataset) => {
    console.log("updateUnarchiveDataset", "called");
    dispatch(unArchiveDataset(dataset));
  };

  const getCompetitions = (page) => {
    dispatch(loadingCompetitions(true));
    const token = localStorage.getItem("_key_usr_tkn");
    let headers = {}
    if (token) {
      headers = {
        Authorization: `Token ${token}`,
      }
    }
    const config = {
      method: "get",
      url: `${API_URL}/competition/${page ? "?page=" + page : ""}`,
      headers
    };
    axios(config)
      .then((res) => {
        const competitions = res.data;
        console.log("competitions-LIST", competitions);
        dispatch(loadCompetitions(competitions));
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingCompetitions(false));
      });
  };

  const updateCompetition = (data) => {
    dispatch(setJoinCompetitions(data));
  };

  const joinCompetition = (dataset) => {
        
    // dispatch(loadingCompetitions(true));
    dispatch(setJoinCompetitions({
      dataset: dataset,
      loading: true
    }))

    const token = localStorage.getItem('_key_usr_tkn')
    
    const config = {
        method: 'PATCH',
        url: `${API_URL}/join_competition/${dataset}/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    };

    axios(config).then((res) => {
        const data = res.data
        console.log("joinCompetition", data)
        // updateCompetition(data)
      dispatch(setJoinCompetitions({
        ...data,
        loading: false
      }))
    }).catch((error) => {
        console.log('CATCH', error);
        if (error.response) {
            // setError("Please make sure your credentials are correct.")
        } else {
            // setError("Make sure you are connected to the internet.")
        }
        dispatch(setJoinCompetitions({
          dataset: dataset,
          loading: false
        }))
    })
}


  return (
    <GlobalContext.Provider
      value={{
        state,
        user: state.user,
        sidebar: state.sidebar,
        getUserInfo,
        getUserDatasets,
        updateArchiveDataset,
        updateUnarchiveDataset,
        getUserExperiments,
        getUserArchivedExperiments,
        updateExperimentsStatus,
        emptyUserExperiments,
        getCompetitions,
        joinCompetition,
        updateCompetition,
        login,
        logout,
        setUserUpdate,
        openSideBar,
        createDataSet,
        createMedicalDataSet,
        createAutonomousDataSet,
        getDataSet,
        saveDataset,
        createDatasetOptions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import { Avatar, Box, Container, Grid, Paper } from '@material-ui/core';
import React from 'react';
import Sidebar from '../../Components/Sidebar';
import './style.css'
import Header from '../../Components/Header';
import {CustomTabs} from '../../Components/CustomTabs/CustomTabs';
import {CustomTab} from '../../Components/CustomTabs/CustomTab';
import { TabContext, TabPanel } from '@material-ui/lab';
import DataSetItem from '../../Components/DatasetList';
import ExperimentsList from '../../Components/ExperimentsList';
import ArchivedExperiments from '../../Components/ExperimentsList/ArchivedExperiments';


const Experiments = (props) => {
    const [value, setValue] = React.useState("all"); 

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        <Box paddingX={7}>
            <Box pb={0} className="title-italic-24" >Experiments</Box>
            <Grid justifyContent='space-between' container>
                <Grid item>
                    <Box pb={0} mt={1} ml={5} className="label-margin">
                        <span className="bold-700-12">“</span>
                        <span className="bold-700-12" style={{ fontSize: '10px'}}>Sometimes it is the people no one can imagine anything of who do the things no one can imagine.</span>
                        <span className="bold-700-12">”</span>
                        <span className="bold-700-12" style={{ color: 'var(--textPrimary)', fontWeight: 'normal', fontSize: '10px'}}> ― Alan Turing</span>
                    </Box>
                </Grid>
                <Grid item>
                    <Header />
                </Grid>
            </Grid>
            

            <TabContext value={value}>
                <CustomTabs value={value} onChange={handleChange} aria-label="ant example">
                    <CustomTab value="all" style={{ marginLeft: '1px', borderTopLeftRadius: '5px' }} label="All" />
                    {/* <CustomTab value="shared" label="Shared with you" />*/}
                    <CustomTab value="archived" style={{ borderTopRightRadius: '5px'}} label="Archived" /> 
                </CustomTabs>
                <Paper variant="outlined" style={{ marginBottom: '8px' }}>
                    <TabPanel className="tab-Panel" value={"all"}>
                        <ExperimentsList filter={value} />
                    </TabPanel>
                    <TabPanel className="tab-Panel" value={"shared"}>
                        <ExperimentsList filter={value} />
                    </TabPanel>
                    <TabPanel className="tab-Panel" value={"archived"}>
                        <ArchivedExperiments filter={value} />
                    </TabPanel>
                </Paper>
            </TabContext>
        </Box>
    );
}

export default Experiments;
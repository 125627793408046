import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import "./style.css";
import Header from "../../Components/Header";
import { CustomTabs } from "../../Components/CustomTabs/CustomTabs";
import { CustomTab } from "../../Components/CustomTabs/CustomTab";
import { TabContext, TabPanel } from "@material-ui/lab";
import CompetitionList from "../../Components/CompetitionList";
import CreateCompetitionDialog from "./CreateCompetitionDialog";
import { CreateCompetitionIcon } from "Components/Icon";
import { GlobalContext } from "Context/GlobalState";

const Compete = (props) => {
  // const { user } = useContext(GlobalContext);
  const [value, setValue] = useState("active");
  const [createCompetition, setCreateCompetition] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreateCompetition = () => {
    setCreateCompetition(true);
  };

  return (
    <>
      <Box paddingX={7} className="competitions-list">
        <Box pb={0} className="title-italic-24">
          Competitions
        </Box>

        <Grid
          justifyContent="space-between"
          className="competition-head"
          container
        >
          <Grid style={{ minWidth: "60%", maxWidth: "70%" }} item>
            <Grid alignItems="center" container>
              <Grid item className="avatar-group">
                <Avatar
                  src="https://i.pravatar.cc/40?id=1"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=2"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=3"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=4"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=5"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=6"
                  className={"avatar"}
                />
              </Grid>
              <Grid item>
                <div className="description-text">
                  Partner with leading data scientists who solve your challenges
                  and build a better future.
                </div>
              </Grid>
              <Grid style={{ marginLeft: "auto", marginRight: "12px" }} item>
                <Tooltip title={`Create your own Competition​`}>
                  <Button
                    onClick={handleCreateCompetition}
                    className="create-competition"
                    variant="outlined"
                  >
                    {/* <img src="./images/create-competition.svg" /> */}
                    <CreateCompetitionIcon
                      style={{ width: "140px", height: "32px", fontFamily: "Roboto"}}
                    />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ minWidth: "30%", maxWidth: "40%" }} item>
            <Header />
          </Grid>
        </Grid>

        <TabContext value={value}>
          {/* <CustomTabs value={value} onChange={handleChange} aria-label="ant example">
                        <CustomTab value="active" style={{ marginLeft: '1px', borderTopLeftRadius: '5px' }} label="Active" />
                        <CustomTab value="completed" label="Completed" />
                        <CustomTab value="favorite" style={{ borderTopRightRadius: '5px'}} label="Favorite" />
                    </CustomTabs> */}

          {/* remove marginTop if you want to show tabs */}
          {/* <Paper
            variant="outlined"
            style={{ marginBottom: "8px", marginTop: "48px" }}
          >
            <TabPanel className="tab-Panel" value={"active"}> */}
          <CompetitionList filter={value} />
          {/* </TabPanel> */}
          {/* <TabPanel className="tab-Panel" value={"completed"}>
              <CompetitionList filter={value} />
            </TabPanel>
            <TabPanel className="tab-Panel" value={"favorite"}>
              <CompetitionList filter={value} />
            </TabPanel>
          </Paper> */}
        </TabContext>
      </Box>
      <CreateCompetitionDialog
        open={createCompetition}
        onClose={() => setCreateCompetition(false)}
      />
    </>
  );
};

export default Compete;

import {
    ARCHIVE_DATASET, CREATE_DATASET, CREATING_DATASET, JOIN_COMPETITION, LOADING_ARCHIVED_EXPERIMENTS, LOADING_COMPETITIONS,
    LOADING_DATASETS, LOADING_EXPERIMENTS, LOAD_ARCHIVED_EXPERIMENTS, LOAD_COMPETITIONS,
    LOAD_DATASETS, LOAD_EXPERIMENTS, LOGIN_USER, OPEN_SIDEBAR, SAVE_DATASET_IN_DB, SET_AUTONOMOUS_DATASET, SET_DATASET,
    SET_DATASET_IMAGES, SET_ERROR, SET_MEDICAL_DATASET, UNARCHIVE_DATASET, UPDATE_EXPERIMENTS_STATUS
} from "./Types"

export const setUserLogin = (payload) => {
    return {
        type: LOGIN_USER,
        payload
    }
}


export const setErrorMessage = (payload) => {
    return {
        type: SET_ERROR,
        payload
    }
}

export const setSideBar = (status) => {
    return {
        type: OPEN_SIDEBAR,
        payload: status
    }
}

export const setDataset = (data) => {
    return {
        type: SET_DATASET,
        payload: data
    }
}


export const setMedicalDataset = (data) => {
    return {
        type: SET_MEDICAL_DATASET,
        payload: data
    }
}


export const setAutonomousDataset = (data) => {
    return {
        type: SET_AUTONOMOUS_DATASET,
        payload: data
    }
}


export const setDatasetImages = (data) => {
    return {
        type: SET_DATASET_IMAGES,
        payload: data
    }
}

export const saveDatasetInDB = (data) => {
    return {
        type: SAVE_DATASET_IN_DB,
        payload: data
    }
}

export const setCreatingDataset = (data) => {
    return {
        type: CREATING_DATASET,
        payload: data
    }
}

export const setDatasetOptions = (data) => {
    return {
        type: CREATE_DATASET,
        payload: data
    }
}

export const loadDatasets = (data) => {
    return {
        type: LOAD_DATASETS,
        payload: data
    }
}

export const loadingDatasets = (data) => {
    return {
        type: LOADING_DATASETS,
        payload: data
    }   
}


export const archiveDataset = (data) => {
    return {
        type: ARCHIVE_DATASET,
        payload: data
    }
}

export const unArchiveDataset = (data) => {
    return {
        type: UNARCHIVE_DATASET,
        payload: data
    }
}


export const loadExperiments = (data) => {
    return {
        type: LOAD_EXPERIMENTS,
        payload: data
    }
}

export const loadArchivedExperiments = (data) => {
    return {
        type: LOAD_ARCHIVED_EXPERIMENTS,
        payload: data
    }
}

export const setUpdateExperimentsStatus = (data) => {
    return {
        type: UPDATE_EXPERIMENTS_STATUS,
        payload: data
    }
}


export const loadingExperiments = (data) => {
    return {
        type: LOADING_EXPERIMENTS,
        payload: data
    }   
}

export const loadingArchivedExperiments = (data) => {
    return {
        type: LOADING_ARCHIVED_EXPERIMENTS,
        payload: data
    }   
}


export const loadingCompetitions = (data) => {
    return {
        type: LOADING_COMPETITIONS,
        payload: data
    }   
}

export const loadCompetitions = (data) => {
    return {
        type: LOAD_COMPETITIONS,
        payload: data
    }
}


export const setJoinCompetitions = (data) => {
    return {
        type: JOIN_COMPETITION,
        payload: data
    }
}
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";

import "./style.css";
import {
  Box,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import { useHistory } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import CompetitionCard from "Components/CompetitionCard";

const CompetitionList = (props) => {
  const {
    state: {
      competitions,
      competitions: { loading, list, next },
    },
    getCompetitions,
  } = useContext(GlobalContext);
  let history = useHistory();
  const [page, setPage] = useState(0);
  const appInsights = useAppInsightsContext();
  const trackCompetitionCard = useTrackMetric(appInsights, "CompetitionCard", {});

  useEffect(() => {
    if (list && list.length === 0 && !loading) {
      getCompetitions();
    }
    if (list && list.length > 0 && competitions.next) {
      getCompetitions(page);
    }
  }, [page]);

  const handleClick = (event, row) => {
    history.push({
      pathname: `/competitions/${row.slug}/details/`,
      state: { ...row },
    });
    
    trackCompetitionCard({ "competitionName": row?.title, "competitionSlug": row.slug })
    // appInsights.trackMetric()
  };


  // console.log("LIST", competitions)

  return (
    (list && list.length > 0) ? (
      <Box style={{ display: "flex", flexWrap: 'wrap', marginTop: 16, marginBottom: 16, gap: 16 }}>
        {list.map((competition, index) => (
          <CompetitionCard
            competition={competition}
            index={index}
            onClick={(event) => handleClick(event, competition)}
          />
        ))}
      </Box>
    ) : loading ? (
        <Grid style={{ marginTop: 24 }} container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
          <Grid style={{ marginTop: 24 }}  container justifyContent="center" alignItems="center">
            <div>No competitions found</div>{" "}
          </Grid>
    )
  );
};

export default CompetitionList;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import './style.css';

import Row from './Row';



const ExperimentRow = props => {
    return (
        // <Box className={`archive-experiment-view`}>
        //     <Row {...props} />
        // </Box>

        <>
            {props.showHeader === false && props.expIndex === 0 && (

                <FiberManualRecordIcon
                    style={{
                        color: "#3576A7",
                        fontSize: "14px",
                        marginLeft: "3.5px",
                        marginTop: "4px",
                        opacity: "0.6"
                    }}
                />
            )}
            <Box className={`archive-experiment-view`}>
            <Row {...props} />
            </Box>
        </>
    );
};


export default ExperimentRow;